import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import logo from '../img/Logo.png';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData(event.target);
    
    try {
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-login', {
        method: 'POST',
        body: formData,
      });
  
      const responseData = await response.json();
      console.log(responseData);
  
      if (responseData.status === '1') {
        const authToken = responseData.details.auth_token;
  
        // Handle successful login
        login(authToken);
        navigate("/");
      } else {
        // Handle login error
        console.error('Login failed:', responseData.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  


  return (
    <div>
      <div className="navbar">
        <div className="navbar-left">
          <Link to="/">
            <img src={logo} height="50px" alt="" />
          </Link>
        </div>
        <div className="navbar-right">
          <p>Contact Us: <b>info.karenpeach@gmail.com</b></p>
        </div>
      </div>
      <hr />
      <div className="login-container">
        <div className="form-container">
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" name="email_or_phone" placeholder="Email or Phone Number" required />
            <input type="password" name="password" placeholder="Password" required />
            <button type="submit" className="login-btn">Login</button>
            <div className="signup-line">
              <a href="/signup">New User Signup</a>
            </div>
          </form>
        </div>
      </div>
      {/* Footer */}
      <div className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <ul>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms">Returns And Exchange Policy</a>
              </li>
              <li>
                <a href="/story">Our Story </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
