import React from 'react';
import { motion } from 'framer-motion';
import logo from '../img/Logo.png';

const LoadingDots = () => {
  const loadingContainerStyle = {
    display: 'flex',
    flexDirection: 'column', // Arrange items vertically
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7', // Optional: Adjust as needed
    textAlign: 'center',
  };

  const logoStyle = {
    width: '150px', // Adjust the size of the logo
    marginBottom: '20px', // Space between the logo and the message
  };

  const messageStyle = {
    fontSize: '18px',
    color: '#333', // Optional: Adjust text color
    marginBottom: '20px', // Space between the message and the dots
  };

  const dotStyle = {
    width: '10px',
    height: '10px',
    margin: '0 5px',
    backgroundColor: '#333', // Optional: Adjust color
    borderRadius: '50%',
  };

  return (
    <div style={loadingContainerStyle}>
      {/* Replace 'your-logo.png' with the actual path to your logo image */}
      <img src={logo}alt="Website Logo" style={logoStyle} />
      <div style={messageStyle}>
        Welcome! We're preparing something special for you...
      </div>
      <div style={{ display: 'flex' }}>
        <motion.div
          style={dotStyle}
          animate={{ y: [0, -10, 0] }}
          transition={{ repeat: Infinity, duration: 0.5, ease: 'easeInOut' }}
        />
        <motion.div
          style={dotStyle}
          animate={{ y: [0, -10, 0] }}
          transition={{ repeat: Infinity, duration: 0.5, ease: 'easeInOut', delay: 0.2 }}
        />
        <motion.div
          style={dotStyle}
          animate={{ y: [0, -10, 0] }}
          transition={{ repeat: Infinity, duration: 0.5, ease: 'easeInOut', delay: 0.4 }}
        />
      </div>
    </div>
  );
};

export default LoadingDots;
