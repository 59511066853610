import React, { useState, useEffect,useContext } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import { LocationContext } from './LocationContext';
import { useWishlist } from './WishlistContext'; // Update the path
import { useAuth } from './AuthContext';
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function ProductComponentss() {
  const [products, setProducts] = useState({ women: [], kids: [] });
  const { addToWishlist, removeFromWishlist, wishlist } = useWishlist();
  const { authToken } = useAuth();
  const { country } = useContext(LocationContext);

  useEffect(() => {
    fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-home-page-products')
      .then(response => response.json())
      .then(data => {
        if (data.status === '1') {
          setProducts(data.details);
          console.log(data.details);
        } else {
          console.error('Error fetching products:', data.message);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [authToken]);
  const isProductInWishlist = (productId) => {
    if (authToken) {
    return wishlist.some(item => item.productId === productId);
      
    } else {
    return wishlist.some(item => item.id === productId);
      
    }
  };

  const handleAddToWishlist = (product) => {
    if (isProductInWishlist(product.id)) {
      removeFromWishlist(product.id);
    } else {
      addToWishlist(product);
    }
  };

  const renderProductCards = (productArray) => {
    return productArray.map(product => (
      <div className="card card1" key={product.id}>
        <Link to={`/product/${product.id}`} style={{ textDecoration: 'none', color: '#000' }}>
          <div className="media media-2x1 gd-primary">
            <img className="profile-image" src={product.image} alt={product.name} />
          </div>
        </Link>
        <div className="card-body card-b">
          <h5 className="card-title" style={{ textAlign: 'center', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {product.name}
          </h5>
          <hr />
          {country === 'IN' && <p style={{ textAlign: 'center' }}>{product.price_inr} &#8377;</p>}
          {country === 'CH' && <p style={{ textAlign: 'center' }}>{product.price_eur} &#8377;</p>}
          {country === 'CA' && <p style={{ textAlign: 'center' }}>{product.price_cad} &#8377;</p>}
  
          <i className={`far fa-heart favorite-icon ${isProductInWishlist(product.id) ? 'active' : ''}`} style={{
              position: 'relative',
              left: '90%',
              cursor: 'pointer',
              color: isProductInWishlist(product.id) ? 'red' : 'initial',
            }} onClick={() => handleAddToWishlist(product)}></i>
        </div>
      </div>
    ));
  };
  

  return (
    <>
      <div className="container-fluid">
        <h2 className="text-center mt-5">You May Also Like</h2>
        <div className="items mt-5">
          <AliceCarousel
            mouseTracking
            responsive={{
              0: { items: 1 },
              600: { items: 3 },
              1024: { items: 4 },
            }}
            autoPlay
            autoPlayInterval={2000}
            infinite
            buttonsDisabled={true}
            dotsDisabled={false}
          >
            {renderProductCards(products.women)}
          </AliceCarousel>
        </div>

      </div>
    </>
  );
}
