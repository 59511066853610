import React, { useState, useEffect } from 'react';
import { Avatar, Button, Drawer, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { useAuth } from './AuthContext'; // Import the useAuth hook

const RootContainer = styled(Grid)({
  padding: '30px',
  '@media (max-width: 600px)': {
    padding: '10px',
  },
});

const AvatarContainer = styled(Grid)({
  textAlign: 'center',
  marginTop: '49px',
  '@media (max-width: 600px)': {
    marginTop: '20px',
  },
});

const FormContainer = styled(Grid)({
  marginTop: '25px',
  '@media (max-width: 600px)': {
    marginTop: '15px',
  },
});

const SubmitButtonContainer = styled(Grid)({
  marginTop: '20px',
  textAlign: 'center',
});

const SubmitButton = styled(Button)({
  width: '150px',
});

const LinkContainer = styled(Grid)({
  marginTop: '22px',
  marginLeft: '20px',
  '@media (max-width: 600px)': {
    marginTop: '10px',
    marginLeft: '10px',
  },
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'black',
  display: 'block',
  marginBottom: '10px',
  fontSize: '18px',
  '@media (max-width: 600px)': {
    fontSize: '16px',
  },
});

const Sidebar = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '200px',
    height: "600px",
    backgroundColor: 'white',
    color: 'black',
    marginTop: '78px', // Adjusted to start below the Navbar
    zIndex: '-1',
    '@media (max-width: 600px)': {
      width: '150px',
      marginTop: '70px',
    },
  },
});

const MobileContainer = styled(Grid)({
  padding: '10px',
});

const MobileFormContainer = styled(Grid)({
  textAlign: 'center',
});

const MobileSubmitButtonContainer = styled(Grid)({
  marginTop: '15px',
  textAlign: 'center',
});

const MobileSubmitButton = styled(Button)({
  width: '100%',
});

const MyProfile = () => {
  const { authToken } = useAuth();
  const isMobile = useMediaQuery('(max-width:600px)');
  console.log(authToken);
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    avatar: 'https://via.placeholder.com/150', // Default avatar image
  });
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // Fetch user details from the API
    fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-profile', {
      method: 'GET',
      headers: {
        'auth-token': authToken, // Include auth-token in headers
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data); // Log the API response
        if (data.status === '1' && data.details) {
          const userDetails = data.details;
          setUser({
            name: userDetails.name || '',
            email: userDetails.email || '',
            phone: userDetails.phone || '',
            avatar: userDetails.image || 'https://via.placeholder.com/150',
          });
        }
      })
      .catch(error => {
        console.error('Error fetching user details:', error);
      });
  }, [authToken]);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadImage = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-upload-media', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === '1' && data.details) {
            const imageUrl = data.details.media;
            setUser({ ...user, avatar: imageUrl });
            // After uploading image, proceed to update profile
            handleSubmit();
          } else {
            console.error('Error uploading image:', data.message);
          }
        })
        .catch(error => {
          console.error('Error uploading image:', error);
        });
    } else {
      // No file selected, proceed to update profile
      handleSubmit();
    }
  };
  
  const handleSubmit = () => {
    // Construct FormData object
    const formData = new FormData();
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('phone', user.phone);
    formData.append('image', user.avatar); // Assuming user.avatar contains the image URL
    console.log(user.avatar);
    // Make the API request to update user profile
    fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-update-profile', {
      method: 'POST',
      body: formData, // Pass FormData object as the body
      headers: {
        'auth-token': authToken, // Include auth-token in headers
      },
    })
      .then(response => {
        console.log(response);
        if (response.ok) {
          // Update successful
          console.log('Profile updated successfully');
          return response.json(); // Parse and return the JSON response
        } else {
          // Handle error response
          return response.json().then(errorData => {
            throw new Error(errorData.message || 'Profile update failed');
          });
        }
      })
      .then(data => {
        // Check the status in the response JSON
        if (data.status === '1') {
          // Success
          console.log('Profile updated successfully');
        } else {
          // Handle other status codes or messages if needed
          console.error('Profile update failed with status:', data.status);
        }
      })
      .catch(error => {
        console.error('Error updating profile:', error);
      });
  };

  console.log(user);

  if (isMobile) {
    // Mobile view
    return (
      <>
        <Navbar />
        <RootContainer container justifyContent="center">
          <Grid item xs={12}>
            <MobileContainer container direction="column" alignItems="center">
              <Avatar alt="User Avatar" src={user.avatar} sx={{ width: 100, height: 100 }} />
              <MobileFormContainer>
                <form onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    value={user.phone}
                    onChange={handleChange}
                  />
                  <input type="file" accept="image/*" onChange={handleFileChange} />

                  <MobileSubmitButtonContainer>
                    <MobileSubmitButton type="button" variant="contained" color="primary" onClick={uploadImage}>
                      Save Changes
                    </MobileSubmitButton>
                  </MobileSubmitButtonContainer>
                </form>
              </MobileFormContainer>
            </MobileContainer>
          </Grid>
        </RootContainer>
      </>
    );
  }

  // Desktop view
  return (
    <>
      <Navbar />
      <Sidebar anchor="left" open={true} variant="persistent">
        <LinkContainer>
          <StyledLink to="/myprofile">My Profile</StyledLink>
          <StyledLink to="/my-orders">My Orders</StyledLink>
          <StyledLink to="/my-cart">My Cart</StyledLink>
        </LinkContainer>
      </Sidebar>
      <RootContainer container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <AvatarContainer>
            <Avatar alt="User Avatar" src={user.avatar} sx={{ width: 150, height: 150 }} />
          </AvatarContainer>
          <FormContainer>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={user.name}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                value={user.phone}
                onChange={handleChange}
              />
              <input type="file" accept="image/*" onChange={handleFileChange} />

              <SubmitButtonContainer>
                <SubmitButton type="button" variant="contained" color="primary" onClick={uploadImage}>
                  Save Changes
                </SubmitButton>
              </SubmitButtonContainer>
            </form>
          </FormContainer>
        </Grid>
      </RootContainer>
    </>
  );
};

export default MyProfile;
