import React, { useState, useEffect } from 'react';
import { CircularProgress, Accordion, AccordionSummary, AccordionDetails, Grid, Typography, TextField, Button, List, ListItem, ListItemText, Card, CardContent } from '@mui/material';
import { motion } from 'framer-motion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import logo from '../img/Logo.png';
import { Link, useParams } from 'react-router-dom';

function OrderStatus() {
  const { orderId: orderIdParam } = useParams(); // Get orderId from URL parameters
  const [loading, setLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [error, setError] = useState(null);
  const [orderId, setOrderId] = useState(orderIdParam || ''); // Set initial value from URL params or empty

  useEffect(() => {
    if (orderIdParam) {
      fetchOrderInfo(); // Fetch order info if orderId is present in URL
    }
  }, [orderIdParam]);

  const fetchOrderInfo = async (event) => {
    if (event) event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-order-details?orderId=${orderId}`);
      const data = await response.json();

      if (data.status === "1") {
        setOrderInfo(data.details);
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('Failed to fetch order details. Please try again later.');
    }

    setLoading(false);
  };

  return (
    <>
      <div className="navbar">
        <div className="navbar-left">
          <Link to="/">
            <img src={logo} height="50px" alt="Logo" />
          </Link>
        </div>
        <div className="navbar-right">
          <span>
            Free Hotline<br />
            +49 8036 94394-10 Mo. to Fr., 9 am - 5 pm
          </span>
        </div>
      </div>
      <hr />
      <div style={{ padding: '20px', minHeight: '100vh' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              style={{ width: '100%' }}
            >
              <Typography variant="h5" align="center" gutterBottom>
                Track Your Order
              </Typography>
              <form onSubmit={fetchOrderInfo} style={{ textAlign: 'center' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="orderId"
                  label="Order ID"
                  name="orderId"
                  value={orderId} // Set the value of the input to the state variable
                  onChange={(e) => setOrderId(e.target.value)} // Update the state when the input changes
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px' }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Check Order Status'}
                </Button>
              </form>
              {error && <div style={{ marginTop: '20px', color: 'red' }}>{error}</div>}
            </motion.div>
          </Grid>
        </Grid>
        {orderInfo && (
          <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                style={{ width: '100%' }}
              >
                <Typography variant="h6" align="center">Order Details</Typography>
                <List>
                  <ListItem>
                    <ListItemText primary={`Order ID: ${orderInfo.orderId}`} />
                  </ListItem>
                </List>
                {orderInfo.order_details && (
                  <Accordion style={{ marginTop: '20px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f4f4f4', borderRadius: '10px' }}>
                      <Typography variant="subtitle1">View Order Items</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container spacing={2}>
  {orderInfo.order_details.map((item, index) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="subtitle1">
            {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Quantity: {item.quantity}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Unit Price: {item.unit_price} {item.currency}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Total Price: {item.total_price} {item.currency}
          </Typography>
          {item.color && (
            <Typography variant="body2" color="textSecondary">
              Color: {item.color}
            </Typography>
          )}
          {item.size && (
            <Typography variant="body2" color="textSecondary">
              Size: {item.size}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            Date: {item.date_time}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

                    </AccordionDetails>
                  </Accordion>
                )}
                {orderInfo.status <= 3 ? ( // Display order delivery progress bar if status is 1, 2, or 3
                  <Accordion style={{ marginTop: '20px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f4f4f4', borderRadius: '10px' }}>
                      <Typography variant="subtitle1">Order Delivery Progress</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: '40px', padding: '19px' }}>
                      <ProgressBar
                        percent={
                          orderInfo.status === 3 ? 100 : (orderInfo.status === 4 ? 75 : orderInfo.status * 33.333)}
                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                        style={{ width: '100%', height: '20px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '10px !important' }}
                      >
                        <Step key="order-placed" transition="scale">
                          {({ accomplished }) => (
                            <div style={{ position: 'relative' }}>
                              <div style={{ fontSize: '12px', color: '#000', marginTop: '10px', textAlign: 'center' }}>
                                {orderInfo.status >= 1 ? (
                                  <CheckCircleIcon style={{ color: 'green' }} />
                                ) : (
                                  <CancelIcon style={{ color: 'red' }} />
                                )}
                                <div style={{ marginTop: '5px' }}>Order placed</div>
                              </div>
                            </div>
                          )}
                        </Step>
                        <Step key="order-in-transit" transition="scale">
                          {({ accomplished }) => (
                            <div style={{ position: 'relative', left: '25%' }}>
                              <div style={{ fontSize: '12px', color: '#000', marginTop: '10px', textAlign: 'center' }}>
                                {orderInfo.status >= 2 ? (
                                  <CheckCircleIcon style={{ color: 'green' }} />
                                ) : (
                                  <CancelIcon style={{ color: 'red' }} />
                                )}
                                <div style={{ marginTop: '5px' }}>Order in transit</div>
                              </div>
                            </div>
                          )}
                        </Step>
                        <Step key="order-delivered" transition="scale">
                          {({ accomplished }) => (
                            <div style={{ position: 'relative', left: '50%' }}>
                              <div style={{ fontSize: '12px', color: '#000', marginTop: '10px', textAlign: 'center' }}>
                                {orderInfo.status >= 3 ? (
                                  <CheckCircleIcon style={{ color: 'green' }} />
                                ) : (
                                  <CancelIcon style={{ color: 'red' }} />
                                )}
                                <div style={{ marginTop: '5px' }}>Order delivered</div>
                              </div>
                            </div>
                          )}
                        </Step>

                      </ProgressBar>
                    </AccordionDetails>
                  </Accordion>
                ) : ( // Display order return progress bar if status is 4 or 7
                  <Accordion style={{ marginTop: '20px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f4f4f4', borderRadius: '10px' }}>
                      <Typography variant="subtitle1">Order Return Progress</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: '40px', padding: '19px' }}>
                    <ProgressBar
  percent={
    parseInt(orderInfo.status) === 4 ? 100 : 50
  }
  filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
  style={{
    width: '100%',
    height: '20px',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '10px !important'
  }}
>
  <Step key="order-placed" transition="scale">
    {({ accomplished }) => (
      <div style={{ position: 'relative' }}>
        <div style={{ fontSize: '12px', color: '#000', marginTop: '10px', textAlign: 'center' }}>
        {parseInt(orderInfo.status) === 7 || parseInt(orderInfo.status) === 4 ? (
  <CheckCircleIcon style={{ color: 'green' }} />
) : (
  <CancelIcon style={{ color: 'red' }} />
)}

          <div style={{ marginTop: '5px' }}>Order In Transit</div>
        </div>
      </div>
    )}
  </Step>
  <Step key="order-in-transit" transition="scale">
    {({ accomplished }) => (
      <div style={{ position: 'relative', left: '25%' }}>
        <div style={{ fontSize: '12px', color: '#000', marginTop: '10px', textAlign: 'center' }}>
          {parseInt(orderInfo.status) === 4 ? (
            <CheckCircleIcon style={{ color: 'green' }} />
          ) : (
            <CancelIcon style={{ color: 'red' }} />
          )}
          <div style={{ marginTop: '5px' }}>Order Returned</div>
        </div>
      </div>
    )}
  </Step>
</ProgressBar>



                    </AccordionDetails>
                  </Accordion>
                )}
              </motion.div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}

export default OrderStatus;
