import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
  const { authToken, isLoggedIn } = useAuth();
  const [wasFetchedWhileLoggedIn, setWasFetchedWhileLoggedIn] = useState(false);

  const addToWishlist = useCallback(async (productDetailsToAdd) => {
    const { id, size, color } = productDetailsToAdd;

    try {
      if (authToken) {
        const existingIndex = wishlist.findIndex(
          (item) => item.id === id && item.color === color && item.size === size
        );

        if (existingIndex === -1) {
          const formData = new FormData();
          formData.append('productId', id);

          const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-add-product-to-wishlist', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'auth-token': authToken,
            },
          });

          setWishlist(response.data.details);
          localStorage.setItem('wishlist', JSON.stringify(response.data.details));
        }
      } else {
        const existingIndex = wishlist.findIndex(
          (item) => item.id === id && item.color === color && item.size === size
        );

        if (existingIndex === -1) {
          const updatedWishlist = [...wishlist, { ...productDetailsToAdd }];
          setWishlist(updatedWishlist);
          localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
        }
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  }, [wishlist, authToken]);

  const removeFromWishlist = useCallback(async (id, size, color) => {
    try {
      if (authToken) {
        const formData = new FormData();
        formData.append('productId', id);

        const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-remove-product-from-wishlist', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'auth-token': authToken,
          },
        });

        setWishlist(response.data.details);
        localStorage.setItem('wishlist', JSON.stringify(response.data.details));
      } else {
        const updatedWishlist = wishlist.filter(
          (item) => !(item.id === id && item.color === color && item.size === size)
        );

        setWishlist(updatedWishlist);
        localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
      }

      // Fetch wishlist again after removal
      await fetchWishlist();
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  }, [wishlist, authToken]);


  const fetchWishlist = useCallback(async () => {
    try {
      if (isLoggedIn && authToken) {
        const response = await axios.get('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-wishlist', {
          headers: {
            'auth-token': authToken,
          },
        });

        if (response.data.status === '1') {
          setWishlist(response.data.details);
          localStorage.setItem('wishlist', JSON.stringify(response.data.details));
          setWasFetchedWhileLoggedIn(true);
        } else {
          console.error('Error fetching wishlist:', response.data.message);
        }
      }
    } catch (error) {
      console.error('Error fetching wishlist:', error);
    }
  }, [isLoggedIn, authToken, addToWishlist, removeFromWishlist]);

  useEffect(() => {
    const storedWishlist = localStorage.getItem('wishlist');

    const loadWishlist = async () => {
      try {
        if (isLoggedIn && authToken) {
          await fetchWishlist();
        } else if (storedWishlist) {
          setWishlist(JSON.parse(storedWishlist));
        }
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    loadWishlist();
  }, [isLoggedIn, authToken, fetchWishlist]);

  // Clear wishlist when logging out if it was fetched while logged in
  useEffect(() => {
    if (!isLoggedIn && wasFetchedWhileLoggedIn) {
      setWishlist([]); // Clear wishlist when logged out
      localStorage.removeItem('wishlist'); // Remove wishlist from local storage
      setWasFetchedWhileLoggedIn(false); // Reset the flag
    }
  }, [isLoggedIn, wasFetchedWhileLoggedIn]);

  const values = {
    wishlist,
    addToWishlist,
    removeFromWishlist,
  };

  return <WishlistContext.Provider value={values}>{children}</WishlistContext.Provider>;
};

export const useWishlist = () => {
  return useContext(WishlistContext);
};
