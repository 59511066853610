import React, { useState, useEffect } from 'react';
import img from '../img/collage sleepwear.png'; // Assuming you have this image in the specified path
import { Link } from 'react-router-dom';

const ProductRange = () => {
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const fetchSubCategories = async () => {
    try {
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-sub-categories');
      const data = await response.json();
      if (data.status === "1") {
        setSubCategories(data.details.slice(0, 5)); // Display only 5 subcategories
      } else {
        console.error('Error fetching sub-categories:', data.message);
      }
    } catch (error) {
      console.error('Error fetching sub-categories:', error);
    }
  };

  const descriptions = {
    'BUTTON DOWN PJ SET': "Luxury, laidback, throw it in the wash-who said you could'nt have it all? Made with 100% finest cotton in elegant Black shade. Top has durable buttons that stay in place no matter your sleeping positions. High-rise pyjama with cozy elastic waistband.",
    'CAPRI SET': "Soft, subtle yet beautiful set of two that comes with a capri and round neck short sleeved t-shirt. Comes in blend of poly-cotton fabric that is perfect for hot summer days.",
    'SHORTS SET': "A staple piece you'll reach for again and again. This light-weight shorts and t-shirt set is made from Cotton & Polyester mix fabric with ombre dye detailing that looks aesthetically elegant. ",
    'PYJAMA SETS': "A must have comfortable Pyjama Set in beautiful top & bottom matching. Soft, short-sleeved with a relaxed fit round neck T-shirt. A straight-leg pyjama that feels like you are in your coziest dreams that comes with elastic waistband and drawstring for a comfortable fit.",
    'CO-ORD SET': "You can lounge forever in this all over print beautiful Coord Set that comes with comfortable amazing fit. Perfect for all day lounging as the fabric cannot bother you in any way. Narrow bottoms gives that perfect shape to your body with utmost comfort"
  };

  const sectionContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  };

  const imageStyle = {
    width: '100%',
    objectFit: 'cover'
  };

  const contentContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px',
    width: '100%'
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  };

  const buttonStyle = {
    marginTop: '20px',
    top: '0 !important'
  };

  return (
    <>
      <div className="container-fluid section-container " style={{backgroundColor:'white'}}>
        <div className="heading">
          <h2 className="text-center ">Discover Deep Sleepwear</h2>
        </div>
      
        <div className="row no-gutters" >
          <div className="col-md-12">
            <div className="column">
              <img src={img} alt="Image 1" className="deepimg"/>
              <div className="content">
                <div className="para">A good night's sleep with innovative materials</div>
                <h3 className="head mt-4">DEEPSLEEPWEAR</h3>
                <a href="#" className="learn-more-button mt-5">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5" >
        <div className="product-heading text-center">
          <h2>Our Product Range</h2>
        </div>

        {subCategories.map((subCategory, index) => (
          <div className="row" key={index} style={{ marginBottom: '40px' }}>
            <div className="col-md-6" style={contentContainerStyle}>
              {index % 2 === 0 ? (
                <img src={subCategory.image} alt={`SubCategory Image ${index + 1}`} className="img-fluid" style={imageStyle} />
              ) : (
                <div style={contentStyle}>
                  <h1 style={{fontWeight:'bolder',color:"#A67B73"}}>{subCategory.subcategory}</h1>
                  <p>{descriptions[subCategory.subcategory] || "Explore our collection of high-quality products."}</p>
                  <p>{subCategory.description}</p>
                  <Link to={`/category/${subCategory.categoryId}/${subCategory.id}`}>
                    <button className="card-button c-button exbutton" style={{ position: 'relative', top: '0', width: '32vw',backgroundColor:"#A67B73" }}>Explore {subCategory.subcategory}</button>
                  </Link>
                </div>
              )}
            </div>
            <div className="col-md-6" style={contentContainerStyle}>
              {index % 2 === 0 ? (
                <div style={contentStyle}>
                  <h1 style={{fontWeight:'bolder',color:"#A67B73"}}>{subCategory.subcategory}</h1>
                  <p>{descriptions[subCategory.subcategory] || "Explore our collection of high-quality products."}</p>

                  <p>{subCategory.description}</p>
                  <Link to={`/category/${subCategory.categoryId}/${subCategory.id}`}>
                    <button className="card-button c-button exbutton" style={{ position: 'relative', width: '32vw', top: '0',backgroundColor:'#A67B73' }}>Explore {subCategory.subcategory}</button>
                  </Link>
                </div>
              ) : (
                <img src={subCategory.image} alt={`SubCategory Image ${index + 1}`} className="img-fluid" style={imageStyle} />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="container-fluid custom-section-container" style={{backgroundColor:'white'}}>
        <div className="row">
          <div className="col-md-6 custom-image-side" style={contentContainerStyle}>
            <img src="https://img.freepik.com/free-photo/tall-leggy-young-beautiful-elegant-model-woman-formal-wear-posed-stairs_627829-6900.jpg?w=826&t=st=1704353722~exp=1704354322~hmac=d207f78cfb3f3547fd01c74159bce84593d42007dd1d02f3d762424bb609cd09" alt="Image 1" className="custom-deepimg img-fluid" style={imageStyle} />
          </div>
          <div className="col-md-6 custom-content-side mt-5" style={contentStyle}>
            <h2>STAY UPDATED</h2>
            <h2 style={{ fontWeight: 'bold' }}>TRACK YOUR ORDER.</h2>
            <p className="mt-5" style={{ fontSize: '17px' }}>Track your order easily by providing your Order ID, found in the confirmation email sent to your inbox after checkout. Stay informed about the status of your purchase, from processing to delivery. Need assistance? Contact our customer support team for help. Thank you for choosing us!</p>
            <a href="/orderstatus" className="custom-learn-more-button btn btn-dark btn-lg mt-3" style={buttonStyle}>Track Order</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRange;
