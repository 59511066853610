import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import '../css/product.css';
import { Link } from 'react-router-dom';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Navbar from './Navbar.jsx';

export default function CategoryPage() {
  const bannerStyle = {
    backgroundColor: "#d1263f",
    padding: "20px",
    textAlign: "center",
    marginTop: "5rem",
    minHeight: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const titleStyle = {
    color: "white",
    fontSize: "64px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const subtitleStyle = {
    color: "white",
    fontSize: "25px",
    marginBottom: "20px",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const buttonStyle = {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    padding: "10px 20px",
    fontSize: "16px",
    width: "12em",
    margin: "0 10px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
  };

  const moreInfoStyle = {
    marginTop: "20px",
    color: "#FFFFFF",
    fontSize: "14px",
    cursor: "pointer",
    alignSelf: "flex-end",
  };

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { category } = useParams();
  const navigate = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const OpenModal = () => {
    setModalIsOpen(true);
  };

  const CloseModal = () => {
    setModalIsOpen(false);
  };

  const [categories, setCategories] = useState([]);
  const [openCategory, setOpenCategory] = useState(null);
  const [openSubcategories, setOpenSubcategories] = useState({});

  const handleCategoryClick = (categoryType) => {
    setOpenCategory(openCategory === categoryType ? null : categoryType);
    setOpenSubcategories({});
  };

  const handleSubcategoryClick = (categoryId, subcategoryId) => {
    setOpenSubcategories({
      ...openSubcategories,
      [categoryId]: openSubcategories[categoryId] === subcategoryId ? null : subcategoryId
    });
    navigate(`/category/${categoryId}/${subcategoryId}`);
  };

  useEffect(() => {
    const fetchNavbarData = async () => {
      try {
        const response = await axios.get('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-home-page-header');
        if (response.data.status === '1') {
          setCategories(response.data.details.navbar);
        } else {
          console.error('Failed to fetch navbar data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching navbar data:', error);
      }
    };

    fetchNavbarData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (category) {
          let type;
          if (category === 'womens') type = 1;
          else if (category === 'kids') type = 2;
          // Add more conditions for other categories if needed

          let apiUrl = `https://sharmasoftwaresolutions.com/karen_peach/index.php/get-all-products?type=${type}&page=1`;

          const response = await axios.get(apiUrl);

          if (response.data.status === '1') {
            setCards(response.data.details);
          } else if (response.data.status === '0' && response.data.details.length === 0) {
            setCards([]);
          } else {
            console.error('API request was not successful:', response.data.message);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [category]);
  console.log(cards);

  return (
    <>
      <Navbar />

      <div style={bannerStyle}>
        <div style={titleStyle}>An extra 10%<br /> discount*</div>
        <div style={subtitleStyle}>Automatically for a purchase of at least two articles in the final sale</div>
        <div style={buttonContainerStyle}>
          <button
            style={buttonStyle}
            onClick={() => alert("For HER")}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#000000", e.target.style.color = "white")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFFFFF", e.target.style.color = "black")}
          >
            FOR HER
          </button>
          <button
            style={buttonStyle}
            onClick={() => alert("For HIM")}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#000000", e.target.style.color = "white")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFFFFF", e.target.style.color = "black")}
          >
            FOR HIM
          </button>
          <button
            style={buttonStyle}
            onClick={() => alert("For KIDS")}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#000000", e.target.style.color = "white")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFFFFF", e.target.style.color = "black")}
          >
            FOR KIDS
          </button>
        </div>
        <div style={moreInfoStyle} onClick={() => alert("More info")}>
          More info
        </div>
      </div>
      <div style={{ display: 'flex' }}>
  {/* Left Side Navbar */}
  <div className="LargeScreen" style={{ width: '200px', backgroundColor: 'white', padding: '20px', minHeight: '100vh', marginTop: '2em' }}>
    {Object.entries(categories).map(([categoryType, categoryList]) => (
      <React.Fragment key={categoryType}>
        <h2 style={{ marginBottom: '10px', color: '#000', fontSize: '22px', cursor: 'pointer' }} onClick={() => handleCategoryClick(categoryType)}>{categoryType}</h2>
        {openCategory === categoryType && (
          <Collapse in={openCategory === categoryType}>
            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
              {categoryList.map(category => (
                <React.Fragment key={category.id}>
                  <li style={{ marginBottom: '5px', cursor: 'pointer', fontSize: '19px' }}>
                    {category.category}
                    {category.subacategories.length > 0 && (
                      <Collapse in={openCategory === categoryType}>
                        <ul style={{ listStyleType: 'none', paddingLeft: '30px' }}>
                          {category.subacategories.map(subcategory => (
                            <li key={subcategory.id} style={{ marginBottom: '5px', cursor: 'pointer', fontSize: '17px' }} onClick={() => handleSubcategoryClick(category.id, subcategory.id)}>
                              {subcategory.subcategory}
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    )}
                  </li>
                  <hr style={{ border: '1px solid black', margin: '5px 0', width: '15em' }} />
                </React.Fragment>
              ))}
            </ul>
          </Collapse>
        )}
        <hr style={{ border: '1px solid black', margin: '5px 0', width: '15em' }} />
      </React.Fragment>
    ))}
  </div>

  {/* Main Content */}
  <div style={{ flex: 1, padding: '20px',marginLeft:'5em' }}>
    <div className="MobileScreen" style={{ marginBottom: '20px', marginLeft: '5px', marginRight: '5px' }}>
    </div>

    {/* Modal for Mobile Screen */}

    {/* Cards Display */}
    {loading ? (
      <p>Loading...</p>
    ) : (
      <div className="shell">
        <div className="container">
          <div className="row">
            {cards.map((card, index) => (
              <div className="col-md-3" key={card.id}>
                <div className="wsk-cp-product">
                  <div className="wsk-cp-img">
                    <img src={card.image} alt="Product" className="img-responsive" />
                  </div>
                  <div className="wsk-cp-text">
                    <div className="category">
                      <span>{card.category}<b>SLEEPWEAR</b></span>
                    </div>
                    <div className="title-product"  style={{ textAlign: 'center', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <h5>{card.name}</h5>
                    </div>
                    <div className="card-footer">
                      <div className="wcf-left">
                        <span className="price">{`Rs.${card.price_inr}`}</span>
                      </div>
                      <div className="wcf-right">
                        <Link to={`/product/${card.id}`} className="buy-btn">
                          <ShoppingBasketIcon />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )}
  </div>
</div>


    </>
  );
}
