import React from 'react';

const termsAndConditionsStyle = {
  fontFamily: 'Arial, sans-serif',
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  lineHeight: '1.6',
};

const headingStyle = {
  marginTop: '20px',
};

function TermsAndConditions() {
  return (
    <div style={termsAndConditionsStyle}>
      <h1 style={headingStyle}>RETURN/EXCHANGE</h1>
      <p>Every single piece designed &amp; produced at “Karen Peach” goes through stringent
quality checks. Still, if you are unsatisfied with your selection or our quality, please
feel free to return or exchange the product within 14 days from the date of receipt
of the product at your end as we want each of you to be 100% happy with your
purchase.
To be eligible for a Return / Exchange, the products must be in the same condition
that you must have received in, unused, with proper tags and it should have its
original packaging.
To start the Return / Exchange, you can initiate Return / Exchange on the eligible
orders through order page of your registered account or you can directly visit our
returns portal.
The parcel when received undergoes quality checks and on satisfactory completion
of the process Exchange products will be shipped and Refunds will be initiated. In
case of prepaid orders refund will be credited to the original payment method. In
case of COD (Cash On Delivery) orders, amount will be credited to bank account
specified.</p>
      
      <h2 style={headingStyle}>DAMAGE &amp; ISSUES</h2>
      <p>We do a thorough quality check of each item before it is dispatched. However, in
case you receive a wrong, defective or damaged item, you can initiate Return /
Exchange.</p>
      <h2 style={headingStyle}>CANCELLATION</h2>
      <p>Please reach out to us via email info.karenpeach.com or WhatsApp chat option if
you should need to cancel your order. Orders placed on this website can only be
cancelled before they are dispatched.</p>
      <h2 style={headingStyle}>REFUND</h2>
      <p>We will notify you once we&#39;ve received and inspected your returns, and let you know
if the refund was approved. If approved, you&#39;ll be automatically refunded on your
original payment method within 7 working days.
In case you have paid via COD, amount will be credited to bank account specified.
Kindly note it takes some lead time for your bank or credit card company to process
and post the refund. The lead time depends on the bank / credit card company’s
policy once the amount is released from our end.
Note - In case of COD order shipping amount is non refundable at the time of
returns.</p>

      
      </div>
  );
}

export default TermsAndConditions;
