import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import successImage from './success.jpg'; // Correctly import the JPG image

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  background: linear-gradient(135deg, #1e3c72, #2a5298); /* Deep blue gradient */
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
`;

const LeftPane = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: white;
  clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%); /* Diagonal split */

  @media (max-width: 900px) {
    clip-path: none;
    height: 50vh;
    width: 100%;
  }
`;

const RightPane = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  color: white;

  @media (max-width: 900px) {
    align-items: center;
    padding: 20px;
    height: 50vh;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 20px;
  img {
    width: 100px;
    height: 100px;
  }
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 15px;
  font-weight: 600;
  color: #ffffff;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: 300;
  color: #d1e8ff;
`;

const ProgressBar = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  height: 10px;
  margin-bottom: 40px;

  &::after {
    content: '';
    display: block;
    background: #00b4d8;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    animation: fillProgress 1.5s ease-in-out forwards;
  }

  @keyframes fillProgress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
`;

const Button = styled.button`
  background-color: #00b4d8;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0077b6;
    transform: translateY(-2px); /* Subtle lift effect */
  }
`;

const OrderConfirmation = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/'); // Navigate to the homepage or another route
  };

  return (
    <Container>
      <LeftPane>
        <ImageWrapper>
          <img src={successImage} alt="Success" /> {/* Use img element */}
        </ImageWrapper>
      </LeftPane>
      <RightPane>
        <Title>Order Confirmed!</Title>
        <Subtitle>Your order has been successfully placed and is on its way.</Subtitle>
        <ProgressBar />
        <Button onClick={handleNavigate}>Continue Shopping</Button>
      </RightPane>
    </Container>
  );
};

export default OrderConfirmation;
