import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('authToken') || null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!authToken);

  const login = (token) => {
    setAuthToken(token);
    setIsLoggedIn(true);
    localStorage.setItem('authToken', token);
  };
  const logout = async () => {
    try {
      // Make the API request to logout
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-log-out', {
        method: 'DELETE',
        headers: {
          'auth-token': authToken // Send auth token directly without "Bearer" prefix
        }
      });
  
      if (response.ok) {
        const responseData = await response.json();
  
        // Check the status in the response data
        if (responseData.status === '1') {
          // Clear the authentication token and update the state
          setAuthToken(null);
          setIsLoggedIn(false);
          localStorage.removeItem('authToken');
          console.log('Logout successful');
        } else {
          // Handle unexpected response status
          console.error('Unexpected status in logout response:', responseData);
        }
      } else {
        // Handle error response
        const errorData = await response.json();
        throw new Error(errorData.message || 'Logout failed');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  
  

  // Use useEffect to check for authentication on component mount
  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
