import React from 'react';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    paragraph: {
      marginBottom: '20px',
    },
    section: {
      marginBottom: '40px',
    },
    contact: {
      marginTop: '40px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p style={styles.paragraph}>
      This Privacy Policy discloses the privacy practices for Mona Apparels with regard to your use
of online platform www.karenpeach.com (Karen Peach) for online shopping. The company is
committed to maintaining privacy.
      </p>

      <div style={styles.section}>
        <h2 style={styles.heading}>Information We Collect</h2>
        <p style={styles.paragraph}>
        Company is the sole owner of the information collected through Karen Peach platform. We
will not sell, share, transfer or rent any information that relates to a natural person, which
either directly or indirectly, in combination with other information available or likely to be
available with us, is capable of identifying such person including sensitive details like name,
postal address and email id. (“Personal Information”) to others in ways different from what
are disclosed in this statement and the Terms of Use
        </p>
        <p style={styles.paragraph}>
        Company identifies each visitor to our website; our web server automatically recognizes
non-personally identifiable information only, e.g., the IP address, domain type, browser
version, and service provider.
We store information volunteered by You that You may elect to provide to us when You
register or update your account to shop online. We use the information we collect about
You to improve the content of our website, to improve order processing and order
confirmations, to respond to Your inquiries and feedback, and to provide You with a better
personalized shopping experience.
        </p>
        <p style={styles.paragraph}>
        We may also use the information to notify You about important functionality changes to the
website, new services and special offers which we think You’ll find valuable. By using our
website, you consent to the collection and use of this information by the Company.
Company reserves the right to share the information collected hereunder with its own
affiliates. In the event of sale or transfer of all or a portion of our business assets, consumer
information may be one of the business assets that are transferred as part of the
transaction.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>PROTECTION OF INFORMATION</h2>
        <p style={styles.paragraph}>
        We may update this privacy policy at any time, and it will be posted on this platform Karen
Peach as and when it is so updated, so that You are always aware of what information we
collect, how we use it, and the circumstances under which we may disclose it. Company
reserves the right to disclose Personal Information as obligated by law, in response to duly
authorized legal process, governmental requests and as may be necessary to protect the
rights and interests of the Company. Unless stated otherwise, our current privacy policy
applies to all information that Karen Peach has about You and Your account.
        </p>

      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>YOUR EMAIL ADDRESS</h2>
        <p style={styles.paragraph}>
        We may store the email addresses of those who communicate with us via email and add
You to our mailing list when you sign up to receive special promotions and updates. By Your
electing to receive emails from us, we would be able to send you exclusive information and
special offers, which you may otherwise not learn about from our Karen Peach platform.

You may follow the removal instructions located at the bottom of such emails to stop
receiving them. Requesting such removal however, will not prevent you from receiving
email communications relating to any online order you place with us. If You would prefer
not to receive emails from us regarding special sales and exclusive offers, please send an
email to info@karenpeach.com with “Unsubscribe Promotions” in the subject line. If You
use more than one email address to shop with us, send us your messages from all such
email account You use.
        </p>

      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>LINKS</h2>
        <p style={styles.paragraph}>
        Company’s platform may contain links to other sites. The linked sites are not under the
control of the Company. Please be aware that the Company is not responsible for the
privacy practices of such other sites. Company advises You to read the privacy policies of
each and every web site that collects personally identifiable information. If You decide to
access any of the third-party sites linked to Karen Peach platform, you do so entirely at Your
own risk. Any links to any partner of the Karen Peach platform should be the responsibility
of the linking party, and the Company shall not be responsible for notifying of any change in
the name or location of any information on the Company’s platform.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>USE OF THIRD PARTIES</h2>
        <p style={styles.paragraph}>
        We use third parties to help us provide services to You including the fulfilment of orders,
processing of payments, monitoring site activity, conducting surveys, maintaining our
database, administering emails, and administering contents, and to provide aggregate,
comparative information on the performance of our website to us and a select group.
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.heading}>SHARING OF INFORMATION</h2>
        <p style={styles.paragraph}>
        Information will be shared with these third parties only to the extent necessary to provide
the above stated services. The Company may use certain companies that enable You to
establish a membership to participate in digital wallets, payment services, shipping services
or rewards programs. If You elect to do so, we would only disclose to such companies the
information necessary to make their programs work and support Your membership with
them. We are not responsible for the privacy practices of the companies sponsoring these
membership programs and we suggest You review the individual privacy policies of each
company with whom You establish such membership.
Company enter into contracts with third parties to serve ads on our behalf across the
Internet and sometimes on this website. They may collect information about Your visits to
website, and Your interaction with our products and services. They may also use
information on about Your visits to this website and to other websites so as to target
advertisements for goods and services. Such third parties are not permitted to sell or share
Your personally identifiable information as part of this process. If such third parties sell or
share the personally identifiable information as part of the process, the Company shall not
be responsible.
        </p>
      </div>


      <div style={styles.section}>
        <h2 style={styles.heading}>CONDITIONS OF USE</h2>
        <p style={styles.paragraph}>
        Company does not warrant that this website, its servers, or email sent by us or on our
behalf are virus free. Company will not be liable for any damages of any kind arising from
the use of this website, including, but not limited to compensatory, direct, indirect,

incidental, punitive, special or consequential damages, loss of data, goodwill, business
opportunity, income or profit, loss of or damage to property and claims of third parties.
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.heading}>LEGAL REQUESTS</h2>
        <p style={styles.paragraph}>
        Company takes Your right to privacy very seriously and has taken measures to safeguard,
protect and respect Your Personal Information. Company will only disclose Your Personal
Information in the event it is required to do so by law, rule, regulation, law enforcement,
governmental official, legal authority or similar requirements.
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.heading}>GRIEVANCE OFFICER</h2>
        <p style={styles.paragraph}>
        In accordance with the Information Technology Act, 2000 and applicable rules made
thereunder, the Grievance Officer for the purpose of Your personal sensitive information as
governed by the Karen Peach privacy policy is Mr. Abhinav Kapila and can be reached at
info@karenpeach.in
        </p>
      </div>
      <div style={{ ...styles.section, ...styles.contact }}>
        <h2 style={styles.heading}>CONSENT TO THIS POLICY</h2>
        <p style={styles.paragraph}>
        The Terms of Use Agreement is incorporated herein by reference in its entirety.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
