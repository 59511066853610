import React from 'react';

const CancellationPolicy = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    paragraph: {
      marginBottom: '20px',
    },
    section: {
      marginBottom: '40px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Cancellation Policy</h1>
      <p style={styles.paragraph}>
        At Karen Peach, we understand that sometimes plans change. This cancellation policy
        outlines our procedures and your options regarding cancelling orders or services.
      </p>

      <div style={styles.section}>
        <h2 style={styles.heading}>Order Cancellations</h2>
        <p style={styles.paragraph}>
          You may cancel your order within [number of hours/days] hours/days of placing it for a full refund.
          After this period, cancellations may be subject to a cancellation fee.
        </p>
        <p style={styles.paragraph}>
          To cancel an order, please contact our customer support team with your order details.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Subscription Cancellations</h2>
        <p style={styles.paragraph}>
          If you have subscribed to a service with recurring payments, you may cancel your subscription
          at any time. Cancellations will take effect at the end of the current billing cycle.
        </p>
        <p style={styles.paragraph}>
          To cancel a subscription, please log in to your account and follow the cancellation instructions.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Refunds</h2>
        <p style={styles.paragraph}>
          Refunds for cancelled orders or subscriptions will be processed within [number of days] days of
          receiving your cancellation request. Refunds will be issued to the original payment method used
          for the purchase.
        </p>
        <p style={styles.paragraph}>
          Please note that certain products or services may be non-refundable, and refund policies may
          vary depending on the specific circumstances.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Contact Us</h2>
        <p style={styles.paragraph}>
          If you have any questions or concerns about our cancellation policy, please contact us at [contact email].
        </p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
