import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Carousel = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-home-page-header');
        const data = await response.json();
        console.log(data);
        if (data.status === "1") {
          setBanners(data.details.banners);
          console.log(banners);
          setLoading(false);
        } else {
          setError('Failed to fetch banners');
          setLoading(false);
        }
      } catch (error) {
        setError('Failed to fetch banners');
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Manually defined text content for banners
  const bannerContent = [
    { 
      title: 'Special Offer', 
      content: 'Get up to 50% off on selected items!',
      buttons: [
        { text: 'Shop Now', link: '/shop' },
        { text: 'Learn More', link: '/special-offers' }
      ],
      name: 'Women' // Name for the first slide
    },
    { 
      title: 'New Arrivals', 
      content: 'Check out our latest collection!',
      buttons: [
        { text: 'Explore', link: '/new-arrivals' },
        { text: 'View Details', link: '/details' }
      ],
      name: 'Kids' // Name for the second slide
    },
    // Add more banner content as needed
  ];

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <style>
        {`
          .carousel-container {
            width: 100%;
            overflow: hidden;
            position: relative;
            margin-top: 55px;
          }

          .carousel-inner {
            position: relative;
            width: 100%;
            display: flex;
            transition: transform 0.5s ease;
            overflow: hidden;
          }

          .carousel-item {
            flex: 0 0 100%;
            max-width: 100%;
            overflow: hidden;
            position: relative;
          }

          .carousel-image {
            width: 100%;
            height: 35vw;
          }

          .banner-overlay {
            position: absolute;
            top: 40%;
            left: 25%;
            transform: translate(-50%, -50%);
            padding: 20px;
            text-align: center;
            color: #fff;
          }

          .banner-text {
            position: absolute;
            top: 16%;
            left: 24%;
            transform: translate(-50%, -50%);
            padding: 20px;
            text-align: center;
            color: #fff;
          }

          .banner-text h3 {
            font-family: "Montserrat";
            font-size: 74px;
            font-weight: 400;
            line-height: 1.25em;
            color:#fffff !important;
            margin-bottom: 0px;
          }

          .banner-text p {
            font-family: 'Montserrat';
            font-size: 36px;
            margin-bottom: 20px;
          }

          .carousel-control {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.5);
            border: none;
            padding: 10px;
            cursor: pointer;
            transition: background 0.3s ease;
            z-index: 2;
          }

          .carousel-control:hover {
            background: rgba(255, 255, 255, 0.8);
          }

          .carousel-control-prev, .carousel-control-next {
            height: 40px;
            width: 40px;
          }

          .carousel-control-prev {
            left: 10px;
          }

          .carousel-control-next {
            right: 10px;
          }

          .carousel-control-icon {
            font-size: 20px;
            color: #fff;
          }

          .button-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }

          .button-container button {
            margin: 0 10px;
            padding: 8px 16px;
            font-size: 22px;
            background-color: #f7f3ec;
            color: #000;
            border: none;
            // border-radius: 5px;
            cursor: pointer;
            width: 18vw;
            height: auto;
            transition: background-color 0.3s ease;
          }

          // .button-container button:hover {
          //   background-color: #000;
          //   color: #fff;
          // }

          @media (max-width: 768px) {
            .carousel-container {
              margin-top: 60px;
              margin-bottom:0px;
            }

            .carousel-image {
              width: 100%;
              height: 65vw;
            }
            .carousel-control-prev {
              top: 35vw;
              left: 9px;
          }
          .carousel-control-next {
            top: 35vw;
            right: 10px;
        }
            .banner-overlay {
              display: none;
            }

            .banner-text {
              position: relative;
              top: 0;
              background-color:white;
              height:24vw;
              left: auto;
              margin-top:0px;
              transform: none;
              padding: 0px;
              text-align: center;
              color: #000;
            }

            .banner-text h3 {
              font-size: 28px;
              margin-top:12px;
              font-weight:600;
              margin-bottom: 0px;
              color: #000;
            }

            // .banner-text p {
            //   font-size: 23px;
            //   font-weight: bold;
            //   margin-bottom: 3px;
            //   color: #000;
            // }

            .button-container {
              display: flex;
              justify-content: center;
              margin-top: 0px;
              position: absolute;
              top: 75%;
              left: 50%;
              
              background-color: #f7f3ec;
              transform: translate(-50%, -50%);
              text-align: center;
            }

            .button-container button {
              font-size: 17px;
              width: 45vw;
              font-weight: bold;
              height: 13vw;
              background-color: #f7f3ec;
              color:black;
            }
          }
            @media (max-width: 325px){
            .banner-text h3 {
              font-size: 24px;
              font-weight:600;
              background-color:white;
              margin-bottom: 0px;
              color: #000;
            }
            }
        `}
      </style>
      <div className="carousel-container">
        <div className="carousel">
          <div className="carousel-inner">
            {banners.map((banner, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img src={banner.banner} className="carousel-image" alt={`Banner ${index}`} />
                <div className="banner-overlay"></div>
                <div className="banner-text">
                  <h3>{banner.title}</h3>
                  {/* Buttons */}
                  <div className="button-container">
                    {bannerContent[0].buttons.map((button, buttonIndex) => (
                      <button key={buttonIndex}>
                        {button.text}
                      </button>
                    ))}
                  </div>
                  {/* Display name for changing slides */}
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control carousel-control-prev" type="button" data-bs-target=".carousel" data-bs-slide="prev">
            <FontAwesomeIcon icon={faChevronLeft} className="carousel-control-icon" />
          </button>
          <button className="carousel-control carousel-control-next" type="button" data-bs-target=".carousel" data-bs-slide="next">
            <FontAwesomeIcon icon={faChevronRight} className="carousel-control-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;