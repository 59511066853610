import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import img1 from './img1.jpg';
import img2 from './img2.jpg';
import img3 from './img3.jpg';
import img4 from './img4.jpg';
import img5 from './img5.jpg';

const CollectionsSection = () => {
  const sleepCollection = [img1, img2, img3, img1, img2, img3];
  const loungeCollection = [img4, img5, img1, img4, img5, img1];

  const [sleepIndex, setSleepIndex] = useState(0);
  const [loungeIndex, setLoungeIndex] = useState(0);
  const [hoveredCollection, setHoveredCollection] = useState(null);

  useEffect(() => {
    if (hoveredCollection === 'sleep') {
      const interval = setInterval(() => {
        setSleepIndex((prevIndex) => (prevIndex + 1) % sleepCollection.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [hoveredCollection, sleepCollection.length]);

  useEffect(() => {
    if (hoveredCollection === 'lounge') {
      const interval = setInterval(() => {
        setLoungeIndex((prevIndex) => (prevIndex + 1) % loungeCollection.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [hoveredCollection, loungeCollection.length]);


  const navigate =useNavigate();
  const handlesleep=()=>{
    navigate('/collections/12');
  }
  const handleLounge=()=>{
    navigate('/collections/13')
  }
  return (
    <div className="collections-container">
      <h1 className="collections-heading">Discover Our Latest Collections</h1>
      <div className="collections-section">
        {/* Sleep Collection */}
        <div
          className="collection"
          onMouseEnter={() => setHoveredCollection('sleep')}
          onMouseLeave={() => setHoveredCollection(null)}
        >
          <div className="image-container">
            <div className="split-progress-bar">
              {[...Array(sleepCollection.length)].map((_, i) => (
                <div
                  key={i}
                  className={`progress-piece ${
                    i <= sleepIndex && hoveredCollection === 'sleep' ? 'active' : ''
                  }`}
                ></div>
              ))}
            </div>
            <img
              src={sleepCollection[sleepIndex]}
              alt={`Sleep Collection ${sleepIndex + 1}`}
              className="collection-image"
            />
          </div>
          <button onClick={handlesleep} className="shop-button">Shop Necessitous</button>
        </div>

        {/* Lounge Collection */}
        <div
          className="collection"
          onMouseEnter={() => setHoveredCollection('lounge')}
          onMouseLeave={() => setHoveredCollection(null)}
        >
          <div className="image-container">
            <div className="split-progress-bar">
              {[...Array(loungeCollection.length)].map((_, i) => (
                <div
                  key={i}
                  className={`progress-piece ${
                    i <= loungeIndex && hoveredCollection === 'lounge' ? 'active' : ''
                  }`}
                ></div>
              ))}
            </div>
            <img
              src={loungeCollection[loungeIndex]}
              alt={`Lounge Collection ${loungeIndex + 1}`}
              className="collection-image"
            />
          </div>
          <button onClick={handleLounge} className="shop-button">Shop Leisure</button>
        </div>
      </div>
    </div>
  );
};

export default CollectionsSection;
