import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'; // Import React-Select
import useMediaQuery from '@mui/material/useMediaQuery';

export default function FilteredList() {
  const filters = ['Color', 'Size', 'Price Range', 'Material'];
  const [filterOptions, setFilterOptions] = useState({});
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]); // New state for materials
  const [page, setPage] = useState(0);
  const { categoryId, subcategoryId } = useParams();
  const [categories, setCategories] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [minPrice, setMinPrice] = useState(100);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [hasMore, setHasMore] = useState(true);
  const [subcategory, setSubcategory] = useState(null);

  // Fetch Navbar Data
  useEffect(() => {
    const fetchNavbarData = async () => {
      try {
        const response = await axios.get('https://mimidating.com/karen_peach/get-home-page-header');
        if (response.data.status === '1') {
          setCategories(response.data.details.navbar);
        } else {
          console.error('Failed to fetch navbar data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching navbar data:', error);
      }
    };
    fetchNavbarData();
  }, []);

  // Fetch Colors
  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await axios.get('https://mimidating.com/karen_peach/get-colors-list');
        if (response.data.status === '1') {
          setColors(response.data.details);
        } else {
          console.error('Failed to fetch colors:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    };
    fetchColors();
  }, []);

  // Fetch Materials (New useEffect for materials)
  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await axios.get('https://mimidating.com/karen_peach/get-materials-list');
        if (response.data.status === '1') {
          setMaterials(response.data.details);
        } else {
          console.error('Failed to fetch materials:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    };
    fetchMaterials();
  }, []);

    // Define a media query to check if the screen width is less than 600px
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  // Fetch Products
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (categoryId && subcategoryId) {
          let formData = new FormData();
          formData.append('categoryId', categoryId);
          formData.append('sub_categoryId', subcategoryId);
          formData.append('page', page);

          // Handle filter options
          if (filterOptions.color) {
            formData.append('color', filterOptions.color);
          }
          if (filterOptions.size) {
            formData.append('size', filterOptions.size);
          }
          if (filterOptions.price_range_from) {
            formData.append('price_range_from', filterOptions.price_range_from);
          }
          if (filterOptions.price_range_to) {
            formData.append('price_range_to', filterOptions.price_range_to);
          }
          if (filterOptions.material_id) {
            formData.append('material_id', JSON.stringify(filterOptions.material_id)); // Send material_id as JSON string
          }

          const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-products-by-category-subcategory', formData);

          if (response.data.status === '1') {
            setCards(prevCards => (page === 0 ? response.data.details.details : [...prevCards, ...response.data.details.details]));
            setSubcategory(response.data.details.subacategory);
            setHasMore(response.data.details.details.length > 0);
          } else if (response.data.status === '0' && response.data.message === 'No data found') {
            if (page === 0) {
              setCards([]);
            }
            setHasMore(false);
          } else {
            console.error('API request was not successful:', response.data.message);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId, subcategoryId, filterOptions, page]);

  const handleFilterSelect = (filterKey, value) => {
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      [filterKey]: value,
    }));
    setPage(0);
    setHasMore(true);
  };

  const handleMaterialSelect = (selectedOptions) => {
    const selectedMaterialIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFilterOptions(prevOptions => ({ ...prevOptions, material_id: selectedMaterialIds }));
  };
  const materialOptions = materials.map(material => ({
    value: material.id,
    label: material.material,
  }));

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handlePriceChange = (type, value) => {
    if (type === 'min') {
      setMinPrice(value);
      handleFilterSelect('price_range_from', value);
    } else {
      setMaxPrice(value);
      handleFilterSelect('price_range_to', value);
    }
  };

  useEffect(() => {
    if (minPrice || maxPrice) {
      handleFilterSelect('price_range_from', minPrice);
      handleFilterSelect('price_range_to', maxPrice);
    }
  }, [minPrice, maxPrice]);

  const subcategoryDescriptions = {
    "Pyjama Sets": {
      heading: "Breathable",
      heading2:"PYJAMA SETS",
      description: "Indulge in the softness and comfort of our pajama sets, designed to make your sleep experience unforgettable. Treat yourself to a restful night's sleep and waking up feeling refreshed and rejuvenated in our cozy pajama sets."
    },
    "Shorts Set": {
      heading: "Essentials",
      heading2:"SHORTS SET",
      description: "When it comes to relaxed staples, we never fail to deliver the perfect lineup with designs such as this short set. Made from delicately soft fabrics, t-shirt and matching shorts are a dream to lounge in."
    },
    "Capri Set": {
      heading: "Super-soft & Light",
      heading2:"CAPRI SETS",
      description: "The Karen Peach top and capris set brings a hint of coolness to your wardrobe. It's crafted from lightweight cotton blends to ensure premium comfort and is cut for a non-restrictive fit that's perfect for everyday styling."
    },
    "Button Down Pj Set": {
      heading: "Dreams sent straight",
      heading2:"BUTTON DOWN PJ SET",
      description: "The cotton blended shirts, with its crisp lines and whipstitch details, sets the stage for luxurious slumber. The long pants drape effortlessly, a symphony of comfort and style. Imagine yourself curled up with a book, this timeless duo is a night worthy masterpiece."
    },
    "Co-ord Set": {
      heading: "The Anytime",
      heading2:"COORD SET",
      description: "We've rounded up the best of loungewear to meet the cozy, comfy and chic essentials made for more than just lazy days. Co-ordinated sets are perfect for days on-the-go, nights in and beyond, these classic pants and easy tees are redefining lounging."
    }
  };
  
  const { heading,heading2, description } = subcategoryDescriptions[subcategory?.subcategory] || {
    heading: "Explore Our Collection",
    heading2:"",
    description: "Browse through our exclusive collection and find the perfect match for your style and comfort."
  };
  
  return (
    <div style={{ display: 'flex', marginTop: '9vh',backgroundColor:'#f7f3ec'}}>
<button 
        onClick={toggleFilters} 
        className='filter-button'
        style={{ 
          position: 'fixed', 
          top: '8%', 
          left: '10px', 
          zIndex: 1001, 
          backgroundColor: '#d1263f', 
          color: '#fff', 
          border: 'none', 
          borderRadius: '4px', 
          padding: '10px' 
        }}>
        {showFilters ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faFilter} />} Filters
      </button>

      
      {/* Sticky Filter Sidebar */}
      {showFilters && (
        <aside className="filter-sidebar" style={{ 
          position: 'fixed', 
          top: '10%', 
          left: '1%', 
          width: '250px',
          overflowY:'auto',
          maxHeight:'80vh', 
          backgroundColor: '#fff', 
          padding: '20px', 
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
          borderRadius: '8px', 
          zIndex: 1000 
        }}>
          <h3 style={{ marginBottom: '20px', fontSize: '18px', fontWeight: 'bold' }}>Filters</h3>
          {filters.map((filter, index) => (
            <div key={index} style={{ marginBottom: '0px' }}>
              <label style={{ marginBottom: '5px', display: 'block', fontSize: '14px', fontWeight: '500' }}>{filter}</label>
              {index === 0 && (
                <select onChange={(e) => handleFilterSelect('color', e.target.value)} style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}>
                  <option value="">Select Color</option>
                  {colors.map(color => (
                    <option key={color.id} value={color.hexCode}>{color.name}</option>
                  ))}
                </select>
              )}
              {index === 1 && (
                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                  {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map(size => (
                    <button 
                      key={size} 
                      onClick={() => handleFilterSelect('size', size)} 
                      style={{ 
                        padding: '10px', 
                        borderRadius: '4px', 
                        border: '1px solid #ccc', 
                        backgroundColor: filterOptions.size === size ? '#d1263f' : '#fff', 
                        color: filterOptions.size === size ? '#fff' : '#000', 
                        cursor: 'pointer' 
                      }}>
                      {size}
                    </button>
                  ))}
                </div>
              )}
              {index === 2 && (
                <div>
                  <input 
                    type="range" 
                    min="0" 
                    max="10000" 
                    value={minPrice} 
                    onChange={(e) => handlePriceChange('min', e.target.value)} 
                    style={{ width: '100%' }} 
                  />
                  <input 
                    type="range" 
                    min="0" 
                    max="10000" 
                    value={maxPrice} 
                    onChange={(e) => handlePriceChange('max', e.target.value)} 
                    style={{ width: '100%', marginTop: '10px' }} 
                  />
                  <p>Price Range: Rs.{minPrice} - Rs.{maxPrice}</p>
                </div>
              )}
{index === 3 && (
                <Select
                  isMulti
                  options={materialOptions}
                  onChange={handleMaterialSelect}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      padding: '4px',
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: '#d1263f',
                      color: '#fff',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: '#fff',
                    }),
                  }}
                  placeholder="Select Materials"
                />
              )}
            </div>
          ))}
        </aside>
      )}

      <div style={{ 
        flexGrow: 1, 
        paddingLeft: showFilters ? '270px' : '20px', 
        paddingRight: '20px' 
      }}>
        {/* Subcategory Information */}
        {subcategory && (
          <div className="subcategory-info" style={{ display: 'flex',flexDirection:'column', justifyContent:'center',alignItems: 'center', marginBottom: '20px', padding: '20px' }}>
    <img
      src={subcategory.subcat_image}
      alt={subcategory.subcategory}
      style={{
        width: isSmallScreen ? '100%' : '50%',  // 90% width on small screens, 50% on larger screens
        height: '80%',
        objectFit: 'contain',
        marginTop:'10px',
        margin: '2px 0px',
      }}
    />
            <div>

              <h2 style={{fontSize: isSmallScreen ? '15px' : '21px', marginBottom: '1px' }}>{heading}</h2>
              <h2 style={{ fontSize: isSmallScreen ? '14px' : '22px', fontWeight: 'bold', marginBottom: '2px' }}>{heading2}</h2>
              <p style={{ color: '#666', fontSize: isSmallScreen ? '13px' : '18px',width : isSmallScreen ? '100%' : '75%',margin:'1px auto' }}>{description}</p>
            </div>
          </div>
        )}

        {/* Product Grid */}
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', 
          gap: '20px' 
        }}>
          {loading ? (
            <div className="loading-skeleton" style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', 
              gap: '20px' 
            }}>
              {Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="skeleton-card" style={{ backgroundColor: '#f0f0f0', height: '300px', borderRadius: '8px' }}></div>
              ))}
            </div>
          ) : (
            cards.map((card) => (
              <div key={card.id} className="product-card" style={{ 
                backgroundColor: '#fff', 
                borderRadius: '8px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                transition: 'transform 0.3s', 
                position: 'relative' 
              }}>
                <Link to={`/product/${card.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={card.image} alt={card.name} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  <div className="product-info" style={{ padding: '15px' }}>
                    <h4 style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>{card.name}</h4>
                    <p style={{ color: '#d1263f', fontWeight: 'bold', fontSize: '18px' }}>{`Rs.${card.price_inr}`}</p>
                  </div>
                  <div className="add-to-cart" style={{ 
                    position: 'absolute', 
                    bottom: '15px', 
                    right: '15px', 
                    backgroundColor: '#d1263f', 
                    padding: '10px', 
                    borderRadius: '50%', 
                    color: '#fff', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                  }}>
                    <FontAwesomeIcon icon={faShoppingBasket} />
                  </div>
                </Link>
              </div>
            ))
          )}

          {/* Load More Button */}
        </div>
          {cards.length > 0 && hasMore && !loading && (
            <div style={{ marginTop: '30px', textAlign: 'center' }}>
              <button onClick={handleLoadMore} style={{ 
                padding: '10px 20px', 
                backgroundColor: '#d1263f', 
                color: '#fff', 
                border: 'none', 
                borderRadius: '4px', 
                cursor: 'pointer' 
              }}>
                Load More
              </button>
            </div>
          )}
      </div>
    </div>
  );
}
