// LocationContext.js
import React, { createContext, useState } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [country, setCountry] = useState('');

  return (
    <LocationContext.Provider value={{ country, setCountry }}>
      {children}
    </LocationContext.Provider>
  );
};
