import React from 'react';
import Navbar from './Navbar.jsx';
import FilteredList from './FilteredList.jsx'
export default function CategoryPage() {
  // const bannerStyle = {
  //   backgroundColor: "#d1263f",
  //   padding: "20px",
  //   textAlign: "center",
  //   marginTop: "5rem",
  //   minHeight: "450px",
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  // };

  // const titleStyle = {
  //   color: "white",
  //   fontSize: "64px",
  //   fontWeight: "bold",
  //   marginBottom: "10px",
  // };

  // const subtitleStyle = {
  //   color: "white",
  //   fontSize: "25px",
  //   marginBottom: "20px",
  // };

  // const buttonContainerStyle = {
  //   display: "flex",
  //   justifyContent: "center",
  // };

  // const buttonStyle = {
  //   backgroundColor: "#FFFFFF",
  //   color: "#000000",
  //   padding: "10px 20px",
  //   fontSize: "16px",
  //   width:"12em",
  //   margin: "0 10px",
  //   border: "none",
  //   cursor: "pointer",
  //   transition: "background-color 0.3s, color 0.3s",
  // };

  // const moreInfoStyle = {
  //   marginTop: "20px",
  //   color: "#FFFFFF",
  //   fontSize: "14px",
  //   cursor: "pointer",
  //   alignSelf: "flex-end",
  // };

  return (
    <>
      <Navbar />

      {/* <div style={bannerStyle}>
        <div style={titleStyle}>An extra 10%<br/> discount*</div>
        <div style={subtitleStyle}>Automatically for a purchase of at least two articles in the final sale</div>
        <div style={buttonContainerStyle}>
          <button
            style={buttonStyle}
            onClick={() => alert("For HER")}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#000000",e.target.style.color="white")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFFFFF",e.target.style.color="black")}
          >
            FOR HER
          </button>
          <button
            style={buttonStyle}
            onClick={() => alert("For HIM")}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#000000",e.target.style.color="white")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFFFFF",e.target.style.color="black")}
          >
            FOR HIM
          </button>
          <button
            style={buttonStyle}
            onClick={() => alert("For KIDS")}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#000000",e.target.style.color="white")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFFFFF",e.target.style.color="black")}
          >
            FOR KIDS
          </button>
        </div>
        <div style={moreInfoStyle} onClick={() => alert("More info")}>
          More info
        </div>
      </div> */}
      <FilteredList/>
    </>
  );
}
