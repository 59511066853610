import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LocationContext } from './LocationContext';

const Section = () => {
  const [products, setProducts] = useState([]);
  const { country } = useContext(LocationContext);

  useEffect(() => {
    fetchProductData();
  }, [country]);

  const fetchProductData = async () => {
    try {
      let countryMapped = country.toLowerCase();
      if (country === 'IN') {
        countryMapped = 'india';
      } else if (country === 'CA') {
        countryMapped = 'canada';
      } else if (country === 'CH' || country === 'GB') {
        countryMapped = 'europe';
      }

      const response = await fetch(
        `https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-top-selling-products?country=${countryMapped}`
      );
      const data = await response.json();
      if (data.status === '1') {
        setProducts(data.details);
      } else {
        console.error('Failed to fetch product data:', data.message);
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const renderProductCards = (productArray) => {
    return productArray.map((product, index) => (
<div className="advanced-product-card" key={product.id}>
  <Link to={`/product/${product.productId}`} className="advanced-product-link">
    <div className="advanced-product-image-container">
      <img className="advanced-product-image" src={product.image} alt={product.name} />
      <div className="hover-button-container">
        <button className="hover-button">Quick View</button>
      </div>
    </div>
    <div className="advanced-product-info">
      <h6 className="advanced-product-name">{product.name}</h6>
      <p className="advanced-product-price">&#8377; {product.price_inr}</p>
    </div>
  </Link>
</div>

    ));
  };

  return (
    <div className="container-fluid" style={{backgroundColor:'#ffff'}}>
      <div className="advanced-section" style={{ overflow: 'hidden' }}>
        <h2 className="text-center advanced-section-title">Karen Peach's Best Seller</h2>
        <div className="advanced-product-grid">
          {renderProductCards(products.slice(0, 3))}
        </div>
        <div className="advanced-product-grid">
          {renderProductCards(products.slice(3, 6))}
        </div>
        {/*
        <div className="advanced-product-grid last-row">
          {renderProductCards(products.slice(6, 8))}
         </div> */}
      </div>
    </div>
  );
};

export default Section;
