import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import logo from '../img/Logo.png';


const ContactUs = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitting(true);
  
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('query', values.message);
  
      const response = await axios.post('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-contact-us', formData);
      
      if (response.data.status === "1") {
        setSubmitted(true);
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Failed to submit the form. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <>
<div className="navbar">
        <div className="navbar-left">
          <Link to="/">
            <img src={logo} height="50px" alt="" />
          </Link>
        </div>
        <div className="navbar-right">
          <span>
            free Hotline<br />
            +49 8036 94394-10 Mo. to Fr.,9 am - 5 pm
          </span>
        </div>
      </div>
      <hr />
    <div className="contact-us">
      <h1>Contact Us</h1>
      {submitted ? (
        <div className="success-message">
          <p>Thank you for contacting us! We'll get back to you soon.</p>
        </div>
      ) : (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, message: 'Please enter your message' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting} className="submit-button"style={{
            width: '100%',
            padding: 0,
            border: 'none',
            borderRadius: 0,
            backgroundColor: 'black',
            color: 'white',
            cursor: 'pointer',
            fontSize: '18px',
            transition: 'background-color 0.3s ease'
          }}>
                
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      )}
      <style jsx>{`
        .contact-us {
          max-width: 600px;
          margin: 20px auto;
          padding: 40px;
          background-color: #f9f9f9;
          border-radius: 10px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }

        .contact-us h1 {
          text-align: center;
          margin-bottom: 30px;
        }

        .success-message {
          background-color: #dff0d8;
          color: #3c763d;
          border: 1px solid #d6e9c6;
          border-radius: 5px;
          padding: 15px;
          margin-top: 20px;
          text-align: center;
        }

        .submit-button {
          width: 100%;
        }
      `}</style>
    </div>
    </>

  );
};

export default ContactUs;
