import React from 'react';
import Navbar from './Navbar.jsx';
import { Box, Typography, Card, CardContent, CardMedia, IconButton, Button, Divider, useMediaQuery } from '@mui/material';
import { useWishlist } from './WishlistContext'; // Update the path
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

export default function WishlistPage() {
    const { wishlist, removeFromWishlist } = useWishlist();
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const { authToken } = useAuth();

    const handleRemoveFromWishlist = (productId,size, color) => {
        removeFromWishlist(productId ,color ,size);
    };
    const navigate = useNavigate();
    const handleNavigate = (productId) => {
        navigate(`/product/${productId}`);
    }
    
    return (
        <>
            <Navbar />
            <div style={{ padding: '20px', width: '70%', margin: '60px auto' }}>
                <Typography variant="h4" gutterBottom>
                    My Karen Peach wish list
                </Typography>
                <p>
                    See your favourite items at a glance: You can save up to 50 items in your wish list and then simply call up the list to see all your favourites in one place. If you’ve decided to purchase a product, you can directly add the item from this list to the basket.
                </p>
                <Divider style={{ margin: '20px 0', height: '1px', backgroundColor: 'black' }} />
                {wishlist.map((product, index) => (
                    <div key={product.id}>
                        {isSmallScreen ? (
                            // Small screen layout
                            <Card style={{ marginBottom: '20px' }}>
                                <CardMedia
                                    component="img"
                                    alt={product.name}
                                    height="300"
                                    image={product.image ? product.image:product.info[0].images[0].image}
                                    style={{ width: '100%', objectFit: 'contain', margin: '0' }}
                                />
                                <CardContent style={{ padding: '16px' }}>
                                    <Typography variant="h6" component="h2" style={{ marginBottom: '10px' }}>
                                        {product.name}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        Price: {product.price_inr}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                                        Description: {product.description}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            color: 'white',
                                            backgroundColor: 'black',
                                            width: '100%',
                                        }}
                                        onClick={() => handleNavigate(product.productId)}
                                    >
                                        View Details
                                    </Button>
                                    {/* <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{
                                            color: 'black',
                                            backgroundColor: 'white',
                                            marginTop: '10px',
                                            width: '100%',
                                        }}
                                        onClick={() => handleRemoveFromWishlist(product.id)}
                                    >
                                        <DeleteIcon /><span>Remove</span>
                                    </Button> */}
                                    {authToken?<Button
                                                variant="contained"
                                                color="secondary"
                                                style={{
                                                    color: 'black',
                                                    backgroundColor: 'white',
                                                    marginTop: '10px',
                                                    width: '100%',
                                                }}
                                                onClick={() => handleRemoveFromWishlist(product.productId)}
                                            >
                                                <DeleteIcon />
                                            </Button>:<Button
                                                variant="contained"
                                                color="secondary"
                                                style={{
                                                    color: 'black',
                                                    backgroundColor: 'white',
                                                    marginTop: '10px',
                                                    width: '100%',
                                                }}
                                                onClick={() => handleRemoveFromWishlist(product.id, product.size, product.color)}

                                            >
                                                <DeleteIcon /><span>Remove</span>
                                            </Button>}
                                </CardContent>
                            </Card>
                        ) : (
                            // Large screen layout
                            <Card style={{ display: 'flex', marginBottom: '20px', height: '16vw' }}>
                                <CardMedia
                                    component="img"
                                    alt={product.name}
                                    height="300"
                                    image={product.image ? product.image:product.info[0].images[0].image}

                                    style={{ width: '18%', objectFit: 'contain', margin: '0' }}
                                />
                                <CardContent style={{ width: '60%', position: 'relative' }}>
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '9%',
                                            transform: 'translateY(-50%)',
                                            width: '80%', // Adjust the width as needed
                                        }}
                                    >
                                        <Typography variant="h6" component="h2" style={{ marginBottom: '10px' }}>
                                            {product.name}
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            Price: {product.price_inr}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                                            Description: {product.description}
                                        </Typography>
                                        <Box
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '99%',
                                                transform: 'translateY(-50%)',
                                                width: '50%',
                                            }}
                                        >
                                            {authToken?<Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    color: 'white',
                                                    backgroundColor: 'black',
                                                    marginRight: '10px',
                                                    width: '100%',
                                                }}
                                                onClick={() => handleNavigate(product.productId)}
                                            >
                                                View Details
                                            </Button>:<Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    color: 'white',
                                                    backgroundColor: 'black',
                                                    marginRight: '10px',
                                                    width: '100%',
                                                }}
                                                onClick={() => handleNavigate(product.id)}
                                            >
                                                View Details
                                            </Button>}
                                            {authToken?<Button
                                                variant="contained"
                                                color="secondary"
                                                style={{
                                                    color: 'black',
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                }}
                                                onClick={() => handleRemoveFromWishlist(product.productId)}
                                            >
                                                <DeleteIcon />
                                            </Button>:<Button
                                                variant="contained"
                                                color="secondary"
                                                style={{
                                                    color: 'black',
                                                    backgroundColor: 'white',
                                                    width: '100%',
                                                }}
                                                onClick={() => handleRemoveFromWishlist(product.id, product.size, product.color)}

                                            >
                                                <DeleteIcon /><span>Remove</span>
                                            </Button>}
                                            
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                        {index < wishlist.length - 1 && <Divider style={{ margin: '20px 0', height: '1px', backgroundColor: 'black' }} />}
                    </div>
                ))}
            </div>
        </>
    );
}
