import logo from './logo.svg';
import './App.css';
import Home from './components/Home';

import Login from './components/Login';
import { AuthProvider } from './components/AuthContext';
import { CartProvider } from './components/CartContext';
import { LocationProvider } from './components/LocationContext';
import { WishlistProvider } from './components/WishlistContext'; // Update the path
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './components/Signup';
import MyProfile from './components/MyProfile';
import ProductComponent from './components/ProductComponent';
import CategoryPage from './components/CategoryPage';
import Productcategory from './components/Productcategory';
import Category from './components/Category';
import Cart from './components/Cart';
import Wishlist from './components/WishlistPage';
import Footer from './components/Footer';
import Payment from './components/Payment';
import Checkout from './components/CheckoutForm';
import Terms from './components/TermsAndConditions';
import Story from './components/Story';
import Privacy from './components/PrivacyPolicy';
import CancellationPolicy from './components/CancellationPolicy';
import ContactUs from './components/ContactUs';
import OrderStatus from './components/OrderStatus';
import OrderConfirmation from './components/OrderConfirmation';
import OrdersPage from './components/OrdersPage';
import ProductCard from './components/ProductCard';
import AllProducts from './components/AllProducts';

// Slick Carousel
function App() {
  return (
    <>
    <LocationProvider>
      <AuthProvider>
        <CartProvider>
          <WishlistProvider>
            <Router>
              <Routes>
                <Route path="/" element={<><Home /></>} />
                <Route path="/login" element={<><Login /></>} />
                <Route path="/signup" element={<><Signup /></>} />
                <Route path="/myprofile" element={<><MyProfile /></>} />
                <Route path="/product/:productId" element={<><ProductComponent /></>} />
                <Route path="/category/:categoryId/:subcategoryId" element={<><CategoryPage /></>} />
                <Route path="/productcategory/:categoryId" element={<><Productcategory /></>} />
                <Route path="/category/:category" element={<><Category /></>} />
                <Route path="/collections/:categoryId" element={<><AllProducts /></>} />
                <Route path="/cart" element={<><Cart /></>} />
                <Route path="/wishlist" element={<><Wishlist /></>} />
                <Route path="/footer" element={<><Footer /></>} />
                <Route path="/payment" element={<><Payment /></>} />
                <Route path="/checkout" element={<><Checkout /></>} />
                <Route path="/terms" element={<><Terms /></>} />
                <Route path="/story" element={<><Story /></>} />
                <Route path="/privacy" element={<><Privacy /></>} />
                <Route path="/CancellationPolicy" element={<><CancellationPolicy /></>} />
                <Route path="/contact" element={<><ContactUs /></>} />
                <Route path="/productcard" element={<><ProductCard /></>} />      
                <Route path="/orderstatus" element={<OrderStatus />} />
                <Route path="/orderstatus/:orderId" element={<OrderStatus />} />
                <Route path="/order-confirmation" element={<><OrderConfirmation /></>} />
                <Route path="/OrdersPage" element={<><OrdersPage /></>} />
              </Routes>
            </Router>
          </WishlistProvider>
        </CartProvider>
      </AuthProvider>
    </LocationProvider>
    </>
  );
}

export default App;
