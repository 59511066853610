// Details.jsx

import React, { useState } from 'react';

const Details = ({ onNext }) => {
  const [shippingAddress, setShippingAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleNext = () => {
    // You can perform any validation or additional logic before proceeding to the next step
    if (shippingAddress && paymentMethod) {
      onNext(); // Call the callback to proceed to the next section (e.g., Payment)
    } else {
      alert('Please fill in all details.'); // You can customize the validation message
    }
  };

  return (
    <div className="details-section">
      <h2>Details</h2>
      <label>
        Shipping Address:
        <input
          type="text"
          value={shippingAddress}
          onChange={(e) => setShippingAddress(e.target.value)}
        />
      </label>
      <br />
      <label>
        Payment Method:
        <input
          type="text"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        />
      </label>
      <br />
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default Details;
