import React from 'react';

const Story = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    paragraph: {
      marginBottom: '20px',
    },
    section: {
      marginBottom: '40px',
    },
    contact: {
      marginTop: '40px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>KAREN PEACH-OUR STORY</h1>
      <h4 style={styles.heading}>EST. 2024</h4>
      <p style={styles.paragraph}>
      Founded in 2024, Karen Peach is born with a vision to be affordable premium Indian brand offering fashionable and functional comfort wear for women. We are proud to lend transparency towards the ethical in-house manufacturing, based in India.
      </p>

      <div style={styles.section}>
        <h2 style={styles.heading}>DESIGN</h2>
        <p style={styles.paragraph}>
        Thoughtful and intentional design decisions have been made while creating these beautiful garments. We agonize over every stitch. Details are important, but, not at the cost of over-powering the aesthetic or comfort that a woman will glide in.
For this reason, we work only with natural, light-weight, breathable cotton, silk, modal and linen fabrics with an ultra soft touch, in contemporary cuts and styles for the modern, confident & mindful woman you are.

        </p>

      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Committed to Quality</h2>
        <p style={styles.paragraph}>
        We ensure our garments perform exactly as they ought to. Each piece is the product of years of testing, sourcing, innovating, and producing with only the highest-quality materials and luxury fabrics available. Wear tests, meticulous construction techniques, and high quality finishes — you could say we’re obsessed. The result? Long-lasting pieces that feel as good on you as they look.
        </p>

      </div>



    </div>
  );
};

export default Story;
