import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // Import the truck icon

// Styled components with circular design and modern aesthetics
const TrackOrderButton = styled(Button)({
  backgroundColor: '#e05716',
  background: 'rgb(209, 173, 159)',
  color: 'white',
  fontSize: '16px',
  fontWeight: 'bold',
  width: '85px',
  height: '85px',
  position: 'fixed',
  padding:'5px',
  top: '95%',
  right: '2%',
  transform: 'translateY(-50%)',
  zIndex: 1000,
  borderRadius: '50%', // Makes it a circle
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Softer shadow for aesthetics
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s ease', // Smooth hover transition
  '&:hover': {
    backgroundColor: 'rgb(166, 123, 115)',
    boxShadow: '0 6px 18px rgba(0, 0, 0, 0.15)', // Enhance shadow on hover
    transform: 'translateY(-50%) scale(1.1)', // Slight zoom on hover
  },
});

const Sidebar = () => {
  const [visible, setVisible] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setVisible(true);

    const newTimeout = setTimeout(() => {
      setVisible(false);
    }, 3000);

    setScrollTimeout(newTimeout);
  };

  const handleNavigate = () => {
    navigate('/orderstatus'); // Adjust this path to your desired route
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTimeout]);

  return (
    <>
      <TrackOrderButton
        onClick={handleNavigate}
        style={{ display: visible ? 'flex' : 'none' }}
      >
        <LocalShippingIcon fontSize="large" /> {/* Truck icon */}
        Track Order
      </TrackOrderButton>
    </>
  );
};

export default Sidebar;
