import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import ProductComponentss from "./ProductComponentss";
import { useCart } from "./CartContext";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Navbar from "./Navbar.jsx";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ReactModal from "react-modal";
import { useMediaQuery } from "react-responsive";
import { useWishlist } from "./WishlistContext"; // Update the path
import { ClipLoader } from "react-spinners"; // Import the loader component
import { useAuth } from "./AuthContext";
const ProductComponent = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 768 });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { productId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const { addToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState("");

  const { addToWishlist, removeFromWishlist, wishlist } = useWishlist();
  const { authToken } = useAuth();

  const [loading, setLoading] = useState(true); // Add loading state
  const [ShowModal, setSHowModal] = useState(false);
  const [addedToCart, setAddedToCart] = useState(null);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const handleImageClick = (imageUrl) => {
    const encodedUrl = encodeURI(imageUrl);
    setSelectedImage(encodedUrl);
  };

  const moveImage = (event) => {
    const card = event.currentTarget;
    const background = card.querySelector(".back-image");
    const offsetX = card.offsetWidth / 2;
    const offsetY = card.offsetHeight / 2;

    // Calculate x and y relative to the center of the card
    const x =
      (event.clientX - card.getBoundingClientRect().left - offsetX) * -1;
    const y = (event.clientY - card.getBoundingClientRect().top - offsetY) * -1;

    background.style.transform = `translate(${x}px, ${y}px) scale(2.0)`;
  };

  const resetImageSize = () => {
    const background = document.querySelector(".back-image");
    background.style.transform = "translate(0, 0) scale(1)";
  };

  const [productDetails, setProductDetails] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://sharmasoftwaresolutions.com/karen_peach/index.php/get-product-by-id?productId=${productId}`
        );
        if (response.data.status === "1") {
          setProductDetails(response.data.details);
          // Set the default color to the first color from the fetched data
          if (response.data.details.info.length > 0) {
            const defaultColor = response.data.details.info[0].color;
            setSelectedColor(defaultColor);
          }
          setLoading(false)
        } else {
          console.error("Failed to fetch product details");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    if (productDetails && selectedColor !== "") {
      const selectedColorInfo = productDetails.info.find(
        (info) => info.color === selectedColor
      );
      if (selectedColorInfo) {
        setSelectedImage(selectedColorInfo.images[0].image);
        resetImageSize(); // Reset image size when changing color
      }
    }
  }, [selectedColor, productDetails]);

  const [selectedColorCarousel, setSelectedColorCarousel] = useState("");
  const handleImageClickCarousel = (color) => {
    setSelectedColorCarousel(color);
  };

  // Inside ProductComponent.js
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // Inside ProductComponent.js
  const handleAddToCart = () => {
    if (!productDetails) {
      console.error("Product details are not available.");
      return;
    }

    // Check if a size is selected
    if (!selectedSize) {
      // Show the modal
      setShowModal(true);
      return;
    }

    // Assuming you have the necessary details for the product
    const productDetailsToAdd = {
      id: productId,
      name: productDetails.name,
      price: productDetails.price_inr,
      size: selectedSize,
      image: selectedImage,
      quantity: quantity, // Pass the quantity
      // Add other details as needed
    };
    console.log(productDetailsToAdd);
    

    // Call the addToCart function from the useCart hook
    addToCart(productDetailsToAdd, selectedColor);
    setAddedToCart(productDetailsToAdd);
    setSHowModal(true);
  };

  const handleViewCart = () => {
    setShowModal(false);
    navigate("/cart");
  };

  useEffect(() => {
    const handleScrollTop = () => {
      window.scrollTo(0, 0);
    };

    handleScrollTop();
  }, []);
  const [showChat, setShowChat] = useState(false);

  const toggleChat = () => {
    setShowChat(!showChat);
  };
  const isProductInWishlist = (productId) => {
    if (authToken) {
      return wishlist.some((item) => item.productId === productId);
    } else {
      return wishlist.some((item) => item.id === productId);
    }
  };
  const [activeTab, setActiveTab] = useState("description");

  // Function to handle tab switch
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleAddToWishlist = (productDetails) => {
    if (isProductInWishlist(productDetails.id)) {
      removeFromWishlist(productDetails.id);
    } else {
      addToWishlist(productDetails);
    }
  };
  return (
    <>
      <Navbar />
      <div
        className="container-fluid large-screen-d"
        style={{ marginTop: "100px" }}
      >
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <ClipLoader size={150} color={"#000000"} loading={loading} />
          </div>
          
        ) : (
        <div className="row">
          <div className="col-md-2">
            <div className="col mt-5">
              {productDetails &&
                productDetails.info
                  .filter(
                    (info) =>
                      selectedColor === "" || info.color === selectedColor
                  )
                  .map((info) =>
                    info.images.map((image, index) => (
                      <div
                        className="col-md-4 mt-2 override-margin"
                        key={`${info.id}_${index}`}
                      >
                        <div className="card card-img-top">
                          <img
                            src={image.image}
                            style={{ height: "110px", width: "100px" }}
                            alt={`Image ${info.id}_${index}`}
                            onClick={() => handleImageClick(image.image)}
                          />
                        </div>
                      </div>
                    ))
                  )}
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="moving-card"
              onMouseMove={moveImage}
              onMouseLeave={resetImageSize}
            >
              <div
                className="back-image"
                style={{
                  backgroundImage: `url(${decodeURIComponent(selectedImage)})`,
                }}
              ></div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column">
              {/* Item Number and Rating */}
              <div className="d-flex align-items-center">
                <p
                  className="mr-5 mb-0"
                  style={{ fontSize: "19px", fontWeight: "500" }}
                >
                  ITEM NO: 56788
                </p>
                <p className="text-warning ml-auto mb-0">
                  {[...Array(5)].map((_, i) => (
                    <i className="far fa-star ml-1" key={i}></i>
                  ))}
                </p>
              </div>

              <hr className="my-2" />

              <div className="mt-3 text-center">
                <span style={{ color: "grey", fontWeight: "500" }}>
                  {productDetails && productDetails.title}
                </span>
                <h3
                  className="my-2"
                  style={{ fontWeight: "600", color: "#333" }}
                >
                  {productDetails && productDetails.name}
                </h3>
                <span style={{ color: "grey", fontSize: "18px" }}>
                  <del>&#8377;{productDetails && productDetails.price_inr}</del>
                  &nbsp;&nbsp;
                  <strong className="text-danger">
                    &#8377;{productDetails && productDetails.price_inr}
                  </strong>
                </span>
              </div>

              <div className="mt-3">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="description-tab"
                      data-toggle="tab"
                      href="#description"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                      style={{ fontWeight: "500" }}
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="reviews-tab"
                      data-toggle="tab"
                      href="#reviews"
                      role="tab"
                      aria-controls="reviews"
                      aria-selected="false"
                      style={{ fontWeight: "500" }}
                    >
                      Details
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active p-3"
                    id="description"
                    role="tabpanel"
                    aria-labelledby="description-tab"
                  >
                    <p className="mt-2">
                      {productDetails && productDetails.description}
                    </p>
                    
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="reviews"
                    role="tabpanel"
                    aria-labelledby="reviews-tab"
                  >
                    <h5>Product Details</h5>
                    <ul className="list-unstyled">
                      {productDetails &&
                        productDetails.sub_description.map((item, index) => (
                          <li key={index} className="mb-2">
                            <i className="fas fa-check text-secondary mr-2"></i>
                            {item}
                          </li>
                        ))}
                    </ul>
                    <h6 className="mt-3">Material</h6>
                    <ul className="list-unstyled">
                      {productDetails &&
                        
                          <li  className="mb-2">
                            <i className="fas fa-check text-secondary mr-2"></i>
                             {productDetails.material}

                          </li>}
                    </ul>
                    <h6 className="mt-3">Washing Instructions</h6>
                    <ul className="list-unstyled">
                      {productDetails &&
                        
                          <li  className="mb-2">
                            <i className="fas fa-check text-secondary mr-2"></i>
                            {productDetails && productDetails.washing_description}

                          </li>}
                    </ul>
                    <h6 class="mt-3">Size Chart</h6>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>SIZE(INCHES)</th>
        <th>BUST</th>
        <th>WAIST</th>
        <th>HIPS</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>SMALL</td>
        <td>34</td>
        <td>26</td>
        <td>36</td>
      </tr>
      <tr>
        <td>MEDIUM</td>
        <td>36</td>
        <td>28</td>
        <td>38</td>
      </tr>
      <tr>
        <td>LARGE</td>
        <td>38</td>
        <td>30</td>
        <td>40</td>
      </tr>
      <tr>
        <td>X-LARGE</td>
        <td>40</td>
        <td>32</td>
        <td>42</td>
      </tr>
      <tr>
        <td>XX-LARGE</td>
        <td>42</td>
        <td>34</td>
        <td>44</td>
      </tr>
    </tbody>
  </table>
                  </div>
                </div>
              </div>

              <div className="d-flex mt-3">
                {productDetails &&
                  productDetails.info.map((info) => (
                    <div
                      key={info.color}
                      className={`card mr-3 color-option border-0 ${selectedColor === info.color ? "selected" : ""
                        }`}
                      onClick={() => setSelectedColor(info.color)}
                      style={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={info.images[0].image}
                        className="card-img-top img-fluid shadow-sm"
                        style={{
                          height: "100px",
                          width: "75px",
                          objectFit: "cover",
                        }}
                        alt={`Color ${info.color}`}
                      />
                    </div>
                  ))}
              </div>

              <div className="mt-3 d-flex align-items-center">
                <div className="mt-3 d-flex align-items-center flex-wrap">
                  {productDetails &&
                    productDetails.info.length > 0 &&
                    Object.keys(productDetails.info[0])
                      .filter(
                        (size) =>
                          size !== "id" &&
                          size !== "productId" &&
                          size !== "color" &&
                          size !== "images"
                      )
                      .map((size) => {
                        const quantity = productDetails.info[0][size];
                        const isAvailable = quantity && parseInt(quantity) > 0;

                        return (
                          <div
                            key={size}
                            onClick={() => isAvailable && setSelectedSize(size)}
                            style={{
                              cursor: isAvailable ? "pointer" : "not-allowed",
                              padding: "10px 20px",
                              margin: "5px",
                              borderRadius: "8px",
                              border:
                                selectedSize === size
                                  ? "2px solid rgb(166, 123, 115)"
                                  : "1px solid #ccc",
                              backgroundColor:
                                selectedSize === size ? "rgb(166, 123, 115)" : "#fff",
                              color: selectedSize === size ? "#fff" : "#333",
                              fontWeight: "500",
                              textAlign: "center",
                              minWidth: "60px",
                              position: "relative",
                              opacity: isAvailable ? 1 : 0.5,
                            }}
                          >
                            {size.toUpperCase()}
                            {!isAvailable && (
                              <div
                                style={{
                                  position: "absolute",
                                  // top: "50%",
                                  // left: "50%",
                                  // width: "100%",
                                  // height: "2px",
                                  // backgroundColor: "#ff0000",
                                  // transform:
                                  //   "translate(-50%, -50%) rotate(45deg)",
                                }}
                              ></div>
                            )}
                          </div>
                        );
                      })}
                </div>

                <div className="d-flex align-items-center ml-2">
                  <Button
                    variant="outlined"
                    onClick={handleDecrement}
                    className="shadow-sm"
                    style={{
                      borderRadius: "50%",
                      width: "36px",
                      height: "36px",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    -
                  </Button>
                  <span
                    style={{
                      fontSize: "24px",
                      margin: "0 20px",
                      fontWeight: "600",
                    }}
                  >
                    {quantity}
                  </span>
                  <Button
                    variant="outlined"
                    onClick={handleIncrement}
                    className="shadow-sm"
                    style={{
                      borderRadius: "50%",
                      width: "36px",
                      height: "36px",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>

              <div className="mt-4">
                <button
                  className="btn btn-dark w-100 py-2 rounded-pill shadow-sm"
                  onClick={handleAddToCart}
                  style={{
                    color: "white",
                    fontWeight: "500",
                    fontSize: "16px",
                    letterSpacing: "0.5px",
                    background:'rgb(166, 123, 115)',
                  }}
                >
                  ADD TO BASKET
                </button>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Size Selection</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Please choose a size before adding to the basket.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div
                className="mt-3"
                style={{ width: "100%" }}
                onClick={() => handleAddToWishlist(productDetails)}
              >
                {productDetails &&
                  (isProductInWishlist(productDetails.id) ? (
                    <button className="btn btn-outline-danger w-100 py-2 rounded-pill shadow-sm">
                      REMOVE FROM WISHLIST
                    </button>
                  ) : (
                    <button className="btn btn-outline-dark w-100 py-2 rounded-pill shadow-sm">
                      ADD TO WISHLIST
                    </button>
                  ))}
              </div>
{/* 
              <ul className="list-unstyled mt-4">
                <li className="mb-2">
                  <i className="fas fa-check text-secondary mr-2"></i> Free
                  Shipping
                </li>
                <li>
                  <i className="fas fa-check text-secondary mr-2"></i> 30 Days
                  Free Return
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        )}
      </div>

      <div className="container-fluid small-screen-d mt-5">
      {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <ClipLoader size={150} color={"#000000"} loading={loading} />
          </div>
          
        ) : (
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="section">
              <div className="items mt-5">
                {productDetails && (
                  <AliceCarousel
                    mouseTracking
                    responsive={{
                      0: { items: 1 },
                      600: { items: 3 },
                      1024: { items: 4 },
                    }}
                    autoPlay
                    autoPlayInterval={2000}
                    infinite
                    buttonsDisabled={true} // Disable default navigation buttons
                    dotsDisabled={false} // Enable pagination dots
                  >
                    {productDetails.info
                      .filter(
                        (info) =>
                          selectedColorCarousel === "" ||
                          selectedColorCarousel === info.color
                      )
                      .flatMap((info) =>
                        info.images.map((image, index) => ({
                          image: image.image,
                          color: info.color,
                          id: `${info.id}_${index}`,
                        }))
                      )
                      .map((imageInfo) => (
                        <div
                          className={`card card1 ${selectedColorCarousel === imageInfo.color
                              ? "selected"
                              : ""
                            }`}
                          key={imageInfo.id}
                          onClick={() =>
                            handleImageClickCarousel(imageInfo.color)
                          }
                        >
                          <div className="media media-2x1 gd-primary">
                            <img
                              className="profile-image"
                              src={imageInfo.image}
                              alt={`Image ${imageInfo.id}`}
                            />
                          </div>
                        </div>
                      ))}
                  </AliceCarousel>
                )}
                {/* Repeat for other cards */}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="mt-3 text-center">
              <span style={{ color: "grey" }}>
                {productDetails && productDetails.title}
              </span>
              <h3>{productDetails && productDetails.name}</h3>
              <span style={{ color: "grey" }}>
                <del>&#8377;{productDetails && productDetails.price_inr}</del>
                &nbsp;&nbsp; &#8377;{productDetails && productDetails.price_inr}
              </span>
            </div>
            <div className="mt-3">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "description" ? "active" : ""
                      }`}
                    onClick={() => handleTabClick("description")}
                  >
                    Description
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "details" ? "active" : ""
                      }`}
                    onClick={() => handleTabClick("details")}
                  >
                    Details
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {activeTab === "description" && (
                  <div className="tab-pane fade show active" id="description">
                    <p className="mt-2">{productDetails?.description}</p>
                    <p className="mt-2">Material : {productDetails?.material}</p>
                  </div>
                )}
                {activeTab === "details" && (
                  <div className="tab-pane fade show active" id="details">
                    <div className="mt-2">
                      <h5>Product Details</h5>
                      <ul className="list-unstyled">
                        {productDetails?.sub_description.map((item, index) => (
                          <li key={index}>
                            <i className="fas fa-check text-secondary"></i>{" "}
                            {item}
                          </li>
                        ))}
                      </ul>

                      <h6 className="mt-3">Washing Instructions</h6>
                      <p>{productDetails?.washing_description}</p>

                      <h6 className="mt-3">Category</h6>
                      <p>{productDetails?.category}</p>

                      <h6 className="mt-3">Subcategory</h6>
                      <p>{productDetails?.subcategory}</p>
                      <h6 class="mt-3">Size Chart</h6>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>SIZE(INCHES)</th>
        <th>BUST</th>
        <th>WAIST</th>
        <th>HIPS</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>SMALL</td>
        <td>34</td>
        <td>26</td>
        <td>36</td>
      </tr>
      <tr>
        <td>MEDIUM</td>
        <td>36</td>
        <td>28</td>
        <td>38</td>
      </tr>
      <tr>
        <td>LARGE</td>
        <td>38</td>
        <td>30</td>
        <td>40</td>
      </tr>
      <tr>
        <td>X-LARGE</td>
        <td>40</td>
        <td>32</td>
        <td>42</td>
      </tr>
      <tr>
        <td>XX-LARGE</td>
        <td>42</td>
        <td>34</td>
        <td>44</td>
      </tr>
    </tbody>
  </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <div className="text-center" style={{ fontSize: "19px" }}>
                  ITEM NO: 56788
                </div>
                <div className="text-warning text-center">
                  <i className="far fa-star "></i>
                  <i className="far fa-star ml-1"></i>
                  <i className="far fa-star ml-1"></i>
                  <i className="far fa-star ml-1"></i>
                  <i className="far fa-star ml-1"></i>
                </div>
              </div>
              <hr style={{ border: "1px solid black" }} />
              <div className="d-flex overflow-auto">
                {productDetails &&
                  productDetails.info.map((info) => (
                    <div
                      key={info.color}
                      className={`card mr-3 color-option ${selectedColor === info.color ? "selected" : ""
                        }`}
                      onClick={() => {
                        setSelectedColor(info.color);
                        setSelectedColorCarousel(info.color); // Set selected color for the carousel
                      }}
                    >
                      <img
                        src={info.images[0].image}
                        className="card-img-top img-fluid"
                        style={{ height: "100px", width: "75px" }}
                        alt={`Color ${info.color}`}
                      />
                    </div>
                  ))}
              </div>

              <div className="mt-3 d-flex align-items-center">
              <div className="mt-3 d-flex align-items-center flex-wrap">
                  {productDetails &&
                    productDetails.info.length > 0 &&
                    Object.keys(productDetails.info[0])
                      .filter(
                        (size) =>
                          size !== "id" &&
                          size !== "productId" &&
                          size !== "color" &&
                          size !== "images"
                      )
                      .map((size) => {
                        const quantity = productDetails.info[0][size];
                        const isAvailable = quantity && parseInt(quantity) > 0;

                        return (
                          <div
                            key={size}
                            onClick={() => isAvailable && setSelectedSize(size)}
                            style={{
                              cursor: isAvailable ? "pointer" : "not-allowed",
                              padding: "10px 20px",
                              margin: "5px",
                              borderRadius: "8px",
                              border:
                                selectedSize === size
                                  ? "2px solid rgb(166, 123, 115)"
                                  : "1px solid #ccc",
                              backgroundColor:
                                selectedSize === size ? "rgb(166, 123, 115)" : "#fff",
                              color: selectedSize === size ? "#fff" : "#333",
                              fontWeight: "500",
                              textAlign: "center",
                              minWidth: "60px",
                              position: "relative",
                              opacity: isAvailable ? 1 : 0.5,
                            }}
                          >
                            {size.toUpperCase()}
                            {!isAvailable && (
                              <div
                                style={{
                                  position: "absolute",
                                  // top: "50%",
                                  // left: "50%",
                                  // width: "100%",
                                  // height: "2px",
                                  // backgroundColor: "#ff0000",
                                  // transform:
                                  //   "translate(-50%, -50%) rotate(45deg)",
                                }}
                              ></div>
                            )}
                          </div>
                        );
                      })}
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-outline-dark"
                    onClick={handleDecrement}
                  >
                    -
                  </button>
                  &nbsp;&nbsp;
                  <span style={{ fontSize: "24px" }}>{quantity}</span>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-outline-dark"
                    onClick={handleIncrement}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="mt-3" style={{ width: "100%" }}>
                <button
                  className="btn  button1 w-100"  style={{background:'rgb(166, 123, 115)'}}
                  onClick={handleAddToCart}
                >
                  ADD TO BASKET
                </button>
              </div>
              <div
                className="mt-3"
                style={{ width: "100%" }}
                onClick={() => handleAddToWishlist(productDetails)}
              >
                {productDetails &&
                  (isProductInWishlist(productDetails.id) ? (
                    <button className="btn btn-outline-danger button2 w-100">
                      REMOVE FROM WISHLIST
                    </button>
                  ) : (
                    <button className="btn btn-outline-dark button2 w-100">
                      ADD TO WISHLIST
                    </button>
                  ))}
              </div>

              {/* <ul className="list-unstyled mt-3">
                <li>
                  <i className="fas fa-check text-secondary"></i> Free Shipping
                </li>
                <li>
                  <i className="fas fa-check text-secondary"></i> 30 Days Free
                  Return
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        )}
      </div>

      <ProductComponentss />

      <Footer />
      {isLargeScreen ? (
        <ReactModal
          isOpen={ShowModal}
          onRequestClose={() => setSHowModal(false)}
          contentLabel="Item Added to Cart"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1000,
            },
            content: {
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: "auto", // Ensures the modal stays aligned to the right
              width: "30%", // Adjust this width if necessary
              height: "100%",
              margin: 0,
              padding: "20px",
              borderRadius: "15px 0 0 15px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              animation: "slideInFromRight 0.5s ease-out",
            },
          }}
          centered
        >
          {addedToCart ? (
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={addedToCart.image}
                alt={`Product ${addedToCart.id}`}
                style={{
                  width: "40%",
                  height: "auto",
                  borderRadius: "15px",
                  marginRight: "20px",
                  flexShrink: 0,
                }}
              />
              <div style={{ flex: 1 }}>
                <h5
                  style={{
                    marginBottom: "10px",
                    fontWeight: "600",
                    color: "#333",
                  }}
                >
                  {addedToCart.name}
                </h5>
                <div
                  style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}
                >
                  <p>Quantity: {addedToCart.quantity}</p>
                  <p>Size: {addedToCart.size}</p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#d9263f",
                    }}
                  >
                    ₹{addedToCart.price}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p>No item added to the cart.</p>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
            }}
          >
            <Button
              variant="secondary"
              style={{
                background: "#444",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
              onClick={() => setSHowModal(false)}
            >
              Continue Shopping
            </Button>
            <Button
              variant="primary"
              style={{
                background: "#d9263f",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
              onClick={handleViewCart}
            >
              View Cart
            </Button>
          </div>
        </ReactModal>
      ) : (
        <ReactModal
          isOpen={ShowModal}
          onRequestClose={() => setSHowModal(false)}
          contentLabel="Item Added to Cart"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1000,
            },
            content: {
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: "auto", // Ensures the modal stays aligned to the right
              width: "90%", // Adjust this width if necessary
              height: "60%",
              margin: 0,
              padding: "20px",
              borderRadius: "15px 0 0 15px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              animation: "slideInFromRight 0.5s ease-out",
            },
          }}
          centered
        >
          {addedToCart ? (
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={addedToCart.image}
                alt={`Product ${addedToCart.id}`}
                style={{
                  width: "40%",
                  height: "auto",
                  borderRadius: "15px",
                  marginRight: "20px",
                  flexShrink: 0,
                }}
              />
              <div style={{ flex: 1 }}>
                <h5
                  style={{
                    marginBottom: "10px",
                    fontWeight: "600",
                    color: "#333",
                  }}
                >
                  {addedToCart.name}
                </h5>
                <div
                  style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}
                >
                  <p>Quantity: {addedToCart.quantity}</p>
                  <p>Size: {addedToCart.size}</p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#d9263f",
                    }}
                  >
                    ₹{addedToCart.price}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p>No item added to the cart.</p>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
            }}
          >
            <Button
              variant="secondary"
              style={{
                background: "#444",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
              onClick={() => setSHowModal(false)}
            >
              Continue Shopping
            </Button>
            <Button
              variant="primary"
              style={{
                background: "#d9263f",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
              onClick={handleViewCart}
            >
              View Cart
            </Button>
          </div>
        </ReactModal>
      )}

      <style jsx="true">{`
        @keyframes slideInFromRight {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0);
          }
        }
      `}</style>
    </>
  );
};

export default ProductComponent;
