import React, { useState } from 'react';

export default function LoyaltyProgram() {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', email);

    // Make the API call to subscribe the user
    try {
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-subscribe', {
        method: 'POST',
        body: formData,
      });
      console.log(response.json);
      if (response.ok) {
        setIsSubscribed(true);
      } else {
        console.error('Failed to subscribe user');
      }
    } catch (error) {
      console.error('Error during subscription:', error);
    }
  };
  return (
    <>
    {/* <div className="container-fluid" style={{backgroundColor:'white'}}>
      <h2 className="text-center">OUR LOYALTY PROGRAM: Karen Peach FRIENDS+FOREVER</h2>
      <div className="row mt-5">
        <div className="col-md">
          <img
            className="full-width-image"
            src="https://img.freepik.com/free-photo/aerial-shot-mountainous-landscape-covered-with-snow-foggy-day_181624-15659.jpg?w=826&t=st=1704364003~exp=1704364603~hmac=13de952810121f2d61036794465be4bfad3759b2aeff546f1d7668821e98c8b4"
            alt="Full Width Image"
          />
          <div className="overlay-text">
            <div>
              Friends <span style={{ color: 'red', fontSize: '60px', fontWeight: 'bolder' }}>+</span> Forever
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 text-center">
          <button className="btn btn-dark btn-lg mx-2 large-butt">INFORM</button>
          <button className="btn btn-dark btn-lg mx-2 large-butt">REGISTER</button>
        </div>
      </div>
    </div> */}
    {isSubscribed ? (
        <div className="container mt-5 promo-section">
        <div className="row promo-row">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="text-content">
              <div className="promo-heading">
                <h2 style={{ fontWeight: 100 }}>Thank you for your interest in the newsletter of Karen Peach!</h2>
              </div>
              <div className="promo-paragraph">
                <p>
                  We have just sent you an email to your {email}. Please follow the instructions in the email to complete your subscription to our newsletter. After that, you will receive an email with your exclusive 10% voucher code.
                </p>
              </div>
            </div>
            <div className="tick-icon" style={{marginRight:"-55px"}}>
              <i className="fas fa-check-circle fa-7x text-dark"></i>
            </div>
          </div>
        </div>
      </div>
      
      
          ) : (
    <div className="container mt-5 promo-section">
      <div className="row promo-row">
        <div className="col-md-12">
          <div className="promo-heading">
            <h2 style={{ fontWeight: 100 }}>Never miss any trends and promotions</h2>
          </div>
          <div className="promo-paragraph">
            <p>Get a 10% discount with your next purchase for your first newsletter registration!</p>
          </div>
        </div>
      </div>

      <div className="row">
      <div className="col-md-5">
        <div className="email-form">

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email" className="sr-only">
                  Your Email Address
                </label>
                <input
                  type="email"
                  className="form-control email-input"
                  id="email"
                  placeholder="Your E-mail Address"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <button type="submit" className="btn register-button" style={{background:'rgb(166, 123, 115)'}}>
                <i className="fas fa-envelope"></i> Register Free of Charge
              </button>
            </form>
        </div>
      </div>
        <div className="col-md-7">
          <div>
            <p style={{ textAlign: 'justify', color: '#888', fontWeight: 'lighter' }}>
              As part of your registration, you are consenting to the receipt of regular personalized product recommendations by
              email. Information about personalization, the use of your data, and unsubscribing options can be found in the detailed
              newsletter consent information and the data protection information.
            </p>
          </div>
        </div>
      </div>
    </div>
    )}

    </>
  );
}
