import React, { useEffect, useState } from 'react';
import { useLocation,Link } from 'react-router-dom';
import Navbar from './Navbar';

const ProductCard = () => {
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('ALL');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('query');

    useEffect(() => {
        if (query) {
            fetchProducts();
        }
    }, [query, selectedCategory]);

    const fetchProducts = async () => {
        try {
            let url = `https://sharmasoftwaresolutions.com/karen_peach/index.php/search-product?search=${query}`;
            const response = await fetch(url);
            const data = await response.json();
            if (data.status === '1') {
                let filteredProducts = data.details.search_by_product;
                if (selectedCategory !== 'ALL') {
                    filteredProducts = filteredProducts.filter(product => product.for === selectedCategory);
                }
                setProducts(filteredProducts);
            } else {
                console.error('Error fetching search data:', data.message);
            }
        } catch (error) {
            console.error('Error fetching search data:', error);
        }
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const truncateDescription = (description) => {
        if (!description || typeof description !== 'string') {
            return '';
        }
        const words = description.split(' ');
        if (words.length > 15) {
            return words.slice(0, 15).join(' ') + '...';
        }
        return description;
    };

    return (
        <>
            <Navbar />
            <div style={{ padding: '1px', marginTop: '70px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
                    <label style={{ marginRight: '20px' }}>
                        <input
                            type="radio"
                            name="category"
                            value="ALL"
                            checked={selectedCategory === "ALL"}
                            onChange={handleCategoryChange}
                            style={{ display: 'none' }}
                        />
                        <div style={{
                            backgroundColor: selectedCategory === "ALL" ? '#e85c74' : '#ffffff',
                            color: selectedCategory === "ALL" ? '#ffffff' : '#000000',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}>
                            All
                        </div>
                    </label>
                    <label style={{ marginRight: '20px' }}>
                        <input
                            type="radio"
                            name="category"
                            value="Women"
                            checked={selectedCategory === "Women"}
                            onChange={handleCategoryChange}
                            style={{ display: 'none' }}
                        />
                        <div style={{
                            backgroundColor: selectedCategory === "Women" ? '#e85c74' : '#ffffff',
                            color: selectedCategory === "Women" ? '#ffffff' : '#000000',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}>
                            Women
                        </div>
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="category"
                            value="kids"
                            checked={selectedCategory === "kids"}
                            onChange={handleCategoryChange}
                            style={{ display: 'none' }}
                        />
                        <div style={{
                            backgroundColor: selectedCategory === "kids" ? '#e85c74' : '#ffffff',
                            color: selectedCategory === "kids" ? '#ffffff' : '#000000',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}>
                            Kids
                        </div>
                    </label>
                </div>
            </div>

            <div style={{
                padding: '20px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '24px',
                margin: '7vw auto',
            }}>
                {products.length === 0 ? (
                    <h2>Oops, No Products Found</h2>
                ) : (
                    products.map((product, index) => (
                        <div
                            key={index}
                            style={{
                                width: '300px',
                                borderRadius: '10px',
                                marginTop: '-5vw',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                                border: '1px solid transparent',
                                transition: 'border 0.3s ease, z-index 0.3s ease',
                                zIndex: 0,
                                position: 'relative',
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.border = '1px solid black';
                                e.currentTarget.style.zIndex = 2;
                                e.currentTarget.querySelector('.descriptionContainer').style.opacity = '1';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.border = '1px solid transparent';
                                e.currentTarget.style.zIndex = 0;
                                e.currentTarget.querySelector('.descriptionContainer').style.opacity = '0';
                            }}
                        >
                            <div style={{ padding: '10px' }}>
                                <div style={{ position: 'relative', cursor: 'pointer' }}>
                                    <img
                                        src={product.image || 'https://via.placeholder.com/300'}
                                        alt={product.name || product.category}
                                        style={{
                                            width: '100%',
                                            height: '400px',
                                            objectFit: 'cover',
                                            transition: 'opacity 0.3s ease',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.opacity = '0.8';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.opacity = '1';
                                        }}
                                    />
                                </div>
                                <div style={{ padding: '10px', textAlign: 'center' }}>
                                <Link to={`/product/${product.id}`}  style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <h3 style={{ margin: '0 0 8px' }}>
                                        {product.name || product.category}
                                    </h3>
                                    <p style={{ margin: '0 0 8px', color: '#555' }}>
                                        {product.category}
                                    </p>
                                    <hr style={{ margin: '10px 0' }} />
                                    {product.price_inr && (
                                        <h4 style={{ margin: '0 0 8px' }}>
                                            Rs.{product.price_inr}
                                        </h4>
                                    )}

                                    <div
                                        className="descriptionContainer"
                                        style={{
                                            opacity: '0',
                                            transition: 'opacity 0.3s ease',
                                            maxHeight: '50px',
                                            overflow: 'hidden',
                                            position: 'relative',
                                        }}
                                    >
                                        <hr style={{ margin: '10px 0' }} />
                                        {product.description && (
                                            <p style={{ margin: '0 0 8px' }}>
                                                {truncateDescription(product.description)}
                                            </p>
                                        )}
                                    </div>
                                </Link>
                                </div>

                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default ProductCard;
