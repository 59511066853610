import React, { useState, useEffect } from "react";
import { Container, TextField, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Navbar from './Navbar'

const OrdersPage = () => {
  const { authToken } = useAuth();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-order-history", {
          method: "GET",
          headers: {
            'auth-token': authToken,
          },
        });

        const data = await response.json();
        if (data.status === "1") {
          setOrders(data.details);
        } else {
          console.error("Failed to fetch orders:", data.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [authToken]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredOrders = orders
    .filter((order) => order.orderId.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((order) => (statusFilter ? order.status === statusFilter : true))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
    <Navbar/>
    <Container sx={{ marginTop: 9 }}>
      <h2>Orders</h2>
      <div sx={{ marginBottom: 2 }}>
        <TextField
          label="Search Orders"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ marginRight: 2 }}
        />
        <Select
          value={statusFilter}
          onChange={handleFilterChange}
          displayEmpty
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="">All Statuses</MenuItem>
          <MenuItem value="1">Delivered</MenuItem>
          <MenuItem value="2">Pending</MenuItem>
          <MenuItem value="3">Cancelled</MenuItem>
        </Select>
      </div>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.orderId}</TableCell>
              <TableCell>{order.order_date}</TableCell>
              <TableCell>{order.quantity}</TableCell>

              <TableCell>{`${order.currency}${order.order_value}`}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={() => navigate(`/orderstatus/${order.orderId}`)}>
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={orders.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
    </>
  );
};

export default OrdersPage;
