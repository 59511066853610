import React, { useState, useEffect } from 'react';
import '../css/Login.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../img/Logo.png';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const [loginOption, setLoginOption] = useState('email');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('no');
  const navigate = useNavigate();

  const handleLoginOption = (option) => {
    setLoginOption(option);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      let response;
      if (loginOption === 'email') {
        formData.append('email', email);
        formData.append('password', password);
        response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-register-email', {
          method: 'POST',
          body: formData,
        });
      } else if (loginOption === 'phone') {
        formData.append('phone', phone);
        formData.append('password', password);
        response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-register-phone', {
          method: 'POST',
          body: formData,
        });
      }
      const data = await response.json();
      if (data.status === '1') {
        setVerificationStatus('otp');
      } else {
        if (data.message === 'email already exists' || data.message === 'phone already exists') {
          showToast('Email or phone number already exists, please use a different one.');
        } else {
          showToast('An error occurred. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      showToast('An error occurred. Please try again.');
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      let response;
      if (loginOption === 'email') {
        formData.append('email', email);
        formData.append('otp', otp);
        response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-verify-email-otp', {
          method: 'POST',
          body: formData,
        });
      } else if (loginOption === 'phone') {
        formData.append('phone', phone);
        formData.append('otp', otp);
        response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-verify-phone-otp', {
          method: 'POST',
          body: formData,
        });
      }
      const responseData = await response.json();
      if (responseData.status === '1') {
        setVerificationStatus('success');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        showToast('Invalid OTP, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      showToast('An error occurred. Please try again.');
    }
  };

  const showToast = (message) => {
    toast.error(message);
  };

  useEffect(() => {
    console.log(verificationStatus);
  }, [verificationStatus]);

  return (
    <div>
      <style>
        {`
          @media (max-width: 768px) {
            .footer {
              display: none;
            }
          }
        `}
      </style>
      <ToastContainer />
      <div className="navbar">
        <div className="navbar-left">
          <a href="./">
            <img src={logo} height="50px" alt="Logo" />
          </a>
        </div>
        <div className="navbar-right">
          <span>
            free Hotline<br />
            +49 8036 94394-10 Mo. to Fr., 9 am - 5 pm
          </span>
        </div>
      </div>
      <hr />
      <div className="login-container">
        <div className="form-container">
          <h2>SignUp</h2>
          <div className="login-options">
            <div className="tabs">
              <div
                className={loginOption === 'email' ? 'tab active' : 'tab'}
                onClick={() => handleLoginOption('email')}
              >
                Using Email
              </div>
              <div
                className={loginOption === 'phone' ? 'tab active' : 'tab'}
                onClick={() => handleLoginOption('phone')}
              >
                Using Phone Number
              </div>
            </div>
          </div>
          {verificationStatus !== 'otp' ? (
            <form onSubmit={handleSubmit}>
              {loginOption === 'email' && (
                <input 
                  type="email" 
                  placeholder="Email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
              )}
              {loginOption === 'phone' && (
                <input 
                  type="tel" 
                  placeholder="Phone Number" 
                  value={phone} 
                  onChange={(e) => setPhone(e.target.value)} 
                  required 
                />
              )}
              <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
              <button type="submit" className="login-btn">
                SignUp
              </button>
              <div className="signup-line">
                <a href="/login">Registered User Login</a>
              </div>
            </form>
          ) : (
            <form onSubmit={handleOtpSubmit}>
              <input 
                type="text" 
                placeholder="Enter OTP" 
                value={otp} 
                onChange={(e) => setOtp(e.target.value)} 
                required 
              />
              <button type="submit" className="login-btn">
                Verify OTP
              </button>
            </form>
          )}
          {verificationStatus === 'success' && (
            <div className="success-message">
              Registered successfully! Redirecting to homepage...
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <ul>
              <li>
                <a href="#">Help & FAQs</a>
              </li>
              <li>
                <a href="#">Legal notice</a>
              </li>
              <li>
                <a href="#">Cookie settings</a>
              </li>
              <li>
                <a href="#">General terms and conditions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
