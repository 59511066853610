import React from 'react';
import logo from '../img/Logo.png';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#D1AD9F' }}>
      <div className="container-fluid">
        {/* First Row */}
        <div className="row text-center text-md-left footerClass" style={{ padding: '40px' }}>
          {/* Column 1 */}
          <div className="col-md-3 col-12 mb-4">
            <img src={logo} alt="Logo" className="footer-logo" style={{ maxWidth: '100%', marginBottom: '10px' }} />
            <p>Sophisticated simplicity for the independent mind.</p>
            <div className="social-icons" style={{ display: 'flex', gap: '10px' }}>
              <a href="#" className="social-icon" style={{ textDecoration: 'none', color: '#000', fontSize: '18px' }}>
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="social-icon" style={{ textDecoration: 'none', color: '#000', fontSize: '18px' }}>
                <i className="fab fa-google"></i>
              </a>
              <a href="#" className="social-icon" style={{ textDecoration: 'none', color: '#000', fontSize: '18px' }}>
                <i className="fab fa-behance"></i>
              </a>
              <a href="#" className="social-icon" style={{ textDecoration: 'none', color: '#000', fontSize: '18px' }}>
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          {/* Column 2 - Help & Information */}
          <div className="col-md-3 col-12 mb-4">
            <h5>Help & Information</h5>
            <hr className="underline" style={{ border: '0', borderTop: '2px solid #000', margin: '10px 0', width: '10%' }} />
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>Shipping Info</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>Returns</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>Order Status</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>FAQs</a></li>
            </ul>
          </div>

          {/* Column 3 - About Us */}
          <div className="col-md-3 col-12 mb-4">
            <h5>About Us</h5>
            <hr className="underline" style={{ border: '0', borderTop: '2px solid #000', margin: '10px 0', width: '10%' }} />
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li><a href="/story" style={{ textDecoration: 'none', color: '#000' }}>Our Story</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>Careers</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>Sustainability</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#000' }}>Press</a></li>
            </ul>
          </div>

          {/* Column 4 - Categories */}
          <div className="col-md-3 col-12 mb-4">
            <h5>Categories</h5>
            <hr className="underline" style={{ border: '0', borderTop: '2px solid #000', margin: '10px 0', width: '10%' }} />
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li><a href="/privacy" style={{ textDecoration: 'none', color: '#000' }}>Privacy Policy</a></li>
              <li><a href="/terms" style={{ textDecoration: 'none', color: '#000' }}>Returns And Exchange</a></li>
            </ul>
          </div>
        </div>

        {/* Full Width White Line */}
        <hr className="full-white-line" style={{ borderTop: '1px solid #fff', margin: '0' }} />

        {/* Second Row */}
        <div className="row footer-bottom-row text-center text-md-left footerClass" style={{ padding: '40px' }}>
          <div className="col-md-6 col-12 mb-2">
            <p>© Copyright 2024 | By Developer. Powered by Developer.</p>
          </div>
          <div className="col-md-6 col-12 text-md-right text-center">
            <img src="end-image.png" alt="End Image" className="img-fluid" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
