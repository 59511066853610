import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';

export default function AllProducts() {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const { categoryId } = useParams();
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [categoryId]);
    // Fetch Products
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (categoryId) {
                    const response = await axios.get(`https://sharmasoftwaresolutions.com/karen_peach/index.php/get-products-by-category?categoryId=${categoryId}&page=${page}`);
                    
                    if (response.data.status === '1') {
                        // Update the products array
                        setCards(prevCards => (page === 1 ? response.data.details : [...prevCards, ...response.data.details]));
                        // Check if there are more products to load
                        setHasMore(response.data.details.length > 0);
                    } else if (response.data.status === '0' && response.data.message === 'No data found') {
                        if (page === 1) {
                            setCards([]);
                        }
                        setHasMore(false);
                    } else {
                        console.error('API request was not successful:', response.data.message);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [categoryId, page]);

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <>
            <Navbar />

            <div style={{ marginTop: '12vh', padding: '22px' }}>
                {/* Product Grid */}
                <div style={{ 
                    display: 'grid', 
                    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', 
                    gap: '20px' 
                }}>
                    {loading ? (
                        <div className="loading-skeleton" style={{ 
                            display: 'grid', 
                            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', 
                            gap: '20px' 
                        }}>
                            {Array.from({ length: 8 }).map((_, index) => (
                                <div key={index} className="skeleton-card" style={{ backgroundColor: '#f0f0f0', height: '300px', borderRadius: '8px' }}></div>
                            ))}
                        </div>
                    ) : (
                        cards.map((card) => (
                            <div key={card.id} className="product-card" style={{ 
                                backgroundColor: '#fff', 
                                borderRadius: '8px', 
                                overflow: 'hidden', 
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                                transition: 'transform 0.3s', 
                                position: 'relative' 
                            }}>
                                <Link to={`/product/${card.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <img src={card.image} alt={card.name} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                                    <div className="product-info" style={{ padding: '15px' }}>
                                        <h4 style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>{card.name}</h4>
                                        <p style={{ color: '#d1263f', fontWeight: 'bold', fontSize: '18px' }}>{`Rs.${card.price_inr}`}</p>
                                    </div>
                                    <div className="add-to-cart" style={{ 
                                        position: 'absolute', 
                                        bottom: '15px', 
                                        right: '15px', 
                                        backgroundColor: '#d1263f', 
                                        padding: '10px', 
                                        borderRadius: '50%', 
                                        color: '#fff', 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center' 
                                    }}>
                                        <FontAwesomeIcon icon={faShoppingBasket} />
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}

                    {/* Load More Button */}

                </div>
                {cards.length > 0 && hasMore && !loading && (
                        <div style={{ marginTop: '30px', textAlign: 'center' }}>
                            <button onClick={handleLoadMore} style={{ 
                                padding: '10px 20px', 
                                backgroundColor: '#d1263f', 
                                color: '#fff', 
                                border: 'none', 
                                borderRadius: '4px', 
                                cursor: 'pointer' 
                            }}>
                                Load More
                            </button>
                        </div>
                    )}
            </div>
        </>
    )
}
